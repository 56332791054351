import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    toRoute: ''
  },
  mutations: {
    SHOW_LOADER(state, data) {
      state.isLoading = data
    },
    ROUT_TO(state, data) {
      state.toRoute = data
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    toRoute: state => state.toRoute,

  }
})
