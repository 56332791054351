<template>
    <div class="page_content" v-touch:swipe="swipeHandler">
        <div class="page_content-parts">
            <div class="grey_circle" ref="circle_grey"></div>
            <div class="page_content-data">
                <div class="page_content-title">Langjährige Erfahrung</div>
                <div class="page_content-subtitle">
                    Mit mehr als 22 Jahren Erfahrung hat sich <span>Intercontent </span> zu einer führenden Technologieplattform für
                    Publisher auf dem deutschen Markt mit hohem technischen Lock-in entwickelt. Unsere Technologie wird
                    bereits weltweit eingesetzt, indem wir jeden Monat mehr als 3,5 Millionen Klicks automatisiert
                    kanalisieren.
                </div>
            </div>
            <div class="page_content-girl" ref="bag_girl"></div>
        </div>
        <div class="scroll_content">
            <div class="scroll_text">Scroll Down</div>
            <div class="scroll_img"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Publisher",
        data() {
            return {
                tick: 1,
                halfTick: 1,
                oneTick: 80,
                scrollTick: 1
            }
        },
        methods: {
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        deltaY: 1000
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        deltaY: -1000
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                let circle = this.$refs['circle_grey']
                let bag_girl = this.$refs['bag_girl']
                this.tick += event.deltaY
                this.scrollTick += event.deltaY
                this.halfTick += event.deltaY * 0.2
                this.oneTick += event.deltaY * 0.01
                circle.style.right = this.tick + 'px';
                circle.style.top = this.halfTick + 'px';
                // bag_girl.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                bag_girl.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                if (this.tick >= 1100) {
                    this.tick = 1000
                } else if (this.tick <= 50) {
                    this.tick = 1
                }
                if (this.scrollTick <= -20) {
                    this.$router.push('/')
                } else if (this.scrollTick >= 1700) {
                    this.$router.push('/advertiser')
                }
            }
        },
        mounted() {
            document.addEventListener("wheel", this.handleScroll,);
            this.$store.commit('SHOW_LOADER', false)
        },
        destroyed() {
            document.removeEventListener("wheel", this.handleScroll,);
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }

            &-girl {
                width: 800px;
                height: 90vh;
                background: url("~@/assets/images/woman_with_bags_cut.png");
                background-repeat: no-repeat;
                //transform: translateX(-100px);
                right: -140px;
                position: relative;
                background-position: inherit;
                background-size: cover;
                transition: 1.8s ease-in-out;
                z-index: 2;
                @media screen and (max-width: 1680px) {
                    width: 60%;
                    height: 85vh;
                }
                @media screen and (max-width: 1440px) {
                    height: 90vh;
                }
                @media screen and (max-width: 768px) {
                    width: 75%;
                    height: 55vh;
                    right: -220px;
                    background-position: center;
                }
                @media screen and (max-width: 480px) {
                    width: 100%;
                    height: 60vh;
                    right: 40px;
                }
            }

            &-data {
                transition: .9s ease-in-out;
                margin-top: 2.5rem;
                @media screen and (max-width: 768px) {
                    margin-top: 0;
                }
            }

            &-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                    flex-direction: column-reverse;
                    align-items: baseline;
                }
                @media screen and (max-width: 480px) {
                   max-width: 450px;
                }

                & .grey_circle {
                    position: absolute;
                    top: -75px;
                    right: -150px;
                    border-radius: 50%;
                    width: 750px;
                    height: 750px;
                    background: #F3F3F3;
                    z-index: 1;
                    transition: .8s ease-in-out;
                    @media screen and (max-width: 1280px) {
                        width: 650px;
                        height: 650px;
                    }

                    @media screen and (max-width: 768px) {
                        width: 500px;
                        height: 500px;
                        right: -100px;
                    }
                    @media screen and (max-width: 480px) {
                        width: 450px;
                        height: 450px;
                    }
                }
            }

            &-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 40px;
                @media screen and (max-width: 1680px) {
                    max-width: 630px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 10px;
                }
            }

            &-subtitle {
                max-width: 780px;
                width: 100%;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 3.125rem;
                @media screen and (max-width: 1680px) {
                    max-width: 630px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 1.875rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 500px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                    padding-left: 1rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                    padding-left: 2rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 400px;
                    font-weight: 400;
                }

                & span {
                    color: #FCA400;
                }
            }

            & .scroll {
                &_content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    bottom: 40px;
                    @media screen and (max-width: 768px) {
                        bottom: 15px;
                    }
                }

                &_text {
                    color: #1B224A;
                    font-size: 0.875rem;
                    margin-bottom: 0.313rem;
                }

                &_img {
                    width: 26px;
                    height: 38px;
                    border-radius: 12px;
                    border: 1px solid #1B224A;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        top: 0;
                        left: 6px;
                        background: url("~@/assets/images/arrow_down_blue.svg") no-repeat center;
                        background-size: contain;
                        animation: mymove 2s infinite;
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
    }

    @keyframes mymove {
        from {
            top: 0;
        }
        to {
            top: 20px;
        }
    }
</style>