<template>
    <div class="page_content" v-touch:swipe="swipeHandler">
        <div class="grey_circle" ref="circle_blue"></div>
        <div class="page_content-parts" ref="content_parts">
            <div class="page_content-data" ref="page_data">
                <div class="page_content-title" ref="advertiser_title">Vertrauensvolle Partnerschaft</div>
                <div class="page_content-subtitle" ref="advertiser_sub">Wir pflegen sowohl mit den wichtigsten
                    Netzwerken als auch E-Commerce Unternehmen intensive und vertrauensvolle Partnerschaften. Das
                    funktioniert nur mit herausragendem ROI, den wir erzielen.
                </div>
            </div>
            <div class="page_content-girl" ref="bag_girl_blue"></div>
        </div>
        <div class="scroll_content">
            <div class="scroll_text">Scroll Down</div>
            <div class="scroll_img"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Advertiser",
        data() {
            return {
                halfTick: 1,
                oneTick: 190,
                negativeTick: 1,
                moveTick: 10
            }
        },
        methods: {
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        deltaY: 800
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        deltaY: -800
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                let circle = this.$refs['circle_blue']
                let bag_girl = this.$refs['bag_girl_blue']
                let page_data = this.$refs['page_data']
                let black_man = this.$refs['black_man']
                let page_data_man = this.$refs['page_data_man']
                let advertiser_title = this.$refs['advertiser_title']
                let advertiser_sub = this.$refs['advertiser_sub']
                let content_parts = this.$refs['content_parts']
                this.oneTick += event.deltaY * 0.09
                this.halfTick += event.deltaY * 0.2
                this.negativeTick -= event.deltaY * 0.2
                this.moveTick += event.deltaY * 0.2
                circle.style.transform = "scale(" + (100 + this.halfTick) / 100 + ")";
                bag_girl.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                bag_girl.style.right = this.negativeTick + 'px';
                if (this.halfTick >= 70) {
                    this.halfTick = 71
                } else if (this.halfTick <= 1) {
                    this.halfTick = 1
                }
                if (this.oneTick >= 240) {
                    this.oneTick = 240
                    this.negativeTick = -119
                } else if (this.oneTick <= 170) {
                    this.oneTick = 170
                    this.negativeTick = -19
                    this.$router.push('/publisher')
                }
                if (this.moveTick >= 210) {
                    advertiser_title.innerText = 'Preisvergleich und Google Shopping'
                    advertiser_sub.innerText = 'Wir platzieren die Produkte unserer Partner in Preisvergleichen und in der sichtbaren produktbezogenen Suche innerhalb der Google Dienste und bieten mehr Umsatz ohne Aufwand.'
                    bag_girl.style.right = 400 + 'px';
                    page_data.style.transform = `translateX(calc(100% - 40%))`;
                }
                if (this.moveTick >= 400) {
                    this.$router.push('/wir_sind')
                    // bag_girl.style.opacity = 0
                    // bag_girl.style.visibility = 'hidden'
                    // bag_girl.style.width = 0
                    // page_data.style.opacity = 0
                    // page_data.style.visibility = 'hidden'
                    // page_data.style.width = 0
                    //
                    // black_man.style.opacity = 1
                    // black_man.style.width = '640px'
                    // black_man.style.visibility = 'visible'
                    // page_data_man.style.opacity = 1
                    // page_data_man.style.width = '600px'
                    // page_data_man.style.visibility = 'visible'
                    // content_parts.style.justifyContent = 'space-between'
                }
                // if (this.moveTick >= 300) {
                //     black_man.style.transform = "scale(" + 1.5 + ")";
                //     circle.style.left = 'unset'
                //     circle.style.transform = `translateX(55%)`;
                // }
                // if (this.moveTick >= 420) {
                //     this.$router.push('/wir_sind')
                // }
            }
        },
        mounted() {
            document.addEventListener("wheel", this.handleScroll,);
            this.$store.commit('SHOW_LOADER', false)
        },
        destroyed() {
            document.removeEventListener("wheel", this.handleScroll,);
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            z-index: 9;
            background: url("~@/assets/images/background.png");
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }
            & .grey_circle {
                position: absolute;
                bottom: -75px;
                left: -150px;
                border-radius: 50%;
                width: 750px;
                height: 750px;
                background: #1D53A3;
                opacity: 0.17;
                z-index: -1;
                transition: .8s ease-in-out;
                @media screen and (max-width: 768px) {
                    width: 550px;
                    height: 550px;
                    left: -100px;
                }
                @media screen and (max-width: 480px) {
                    width: 450px;
                    height: 450px;
                }
            }
            &-girl {
                position: absolute;
                right: 100px;
                width: 600px;
                height: 640px;
                background: url("~@/assets/images/blue_dress_girl.png");
                background-repeat: no-repeat;
                background-position: inherit;
                background-size: cover;
                transition: 1.8s ease-in-out;
                transform: scale(2);
                @media screen and (max-width: 1280px) {
                    width: 430px;
                    height: 50vh;
                    background-size: contain;
                }
                @media screen and (max-width: 768px) {
                    width: 350px;
                    height: 50vh;
                    background-size: cover;
                }
                @media screen and (max-width: 480px) {
                    width: 335px;
                    height: 45vh;
                    top: 10px;
                    background-position: left;
                    /*max-width: 260px;*/
                }
            }
            &-data {
                transition: .9s ease-in-out;
                @media screen and (max-width: 480px) {
                    margin-right: 175px;
                    margin-bottom: 1.25rem;
                }
            }
            &-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                   max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;

                }
            }
            &-title {
                color: #fff;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 3.125rem;
                @media screen and (max-width: 1680px) {
                    max-width: 600px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    margin-bottom: 2.5rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.1rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem;
                    max-width: 420px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;

                }
                & .orange_title {
                    color: #FCA400;
                }
            }
            &-subtitle {
                max-width: 780px;
                width: 100%;
                color: #fff;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 2.5rem;
                @media screen and (max-width: 1680px) {
                    max-width: 680px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 1.25rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 500px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                }
                @media screen and (max-width: 768px) {
                    max-width: 320px;
                    font-size: 1.125rem;
                    line-height: 1.8rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 230px;
                    font-weight: 400;
                }
            }
            & .scroll {
                &_content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    bottom: 40px;
                    @media screen and (max-width: 768px) {
                        bottom: 15px;
                    }
                }
                &_text {
                    color: #fff;
                    font-size: 0.875rem;
                    margin-bottom: 5px;
                }
                &_img {
                    width: 26px;
                    height: 38px;
                    border-radius: 12px;
                    border: 1px solid #fff;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        top: 0;
                        left: 6px;
                        background: url("~@/assets/images/arrow_down_white.svg") no-repeat center;
                        background-size: contain;
                        animation: mymove 2s infinite;
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
    }
    @keyframes mymove {
        from {
            top: 0;
        }
        to {
            top: 20px;
        }
    }
</style>