<template>
    <div class="page_content" v-touch:swipe="swipeHandler">
        <div class="gradient_layer"></div>
        <div class="grey_circle" ref="circle_blue"></div>
        <div class="page_content-parts">
            <div class="page_content-data" ref="page_content">
                <div class="page_content-title">Wenn auch Du zu unserem Team gehören und Teil unseres Erfolges sein
                    möchtest
                </div>
                <div class="page_content-subtitle">Bewirb dich jetzt! Zurzeit suchen wir in unserem Büro am Kudamm in
                    Berlin:
                </div>
            </div>
            <div class="page_content-data arrows" ref="arrows">
                <div class="page_content-subtitle">
                    <svg id="Icon_ionic-ios-arrow-dropright1" data-name="Icon ionic-ios-arrow-dropright"
                         xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                        <path id="Path_2325" data-name="Path 2325"
                              d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                              transform="translate(0.327 -1.159)" fill="#fca400"/>
                        <path id="Path_2326" data-name="Path 2326"
                              d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                              transform="translate(-3.375 -3.375)" fill="#fca400"/>
                    </svg>
                    Sales Manager*in (m/w/d)
                </div>
                <div class="page_content-subtitle get_right">
                    <svg id="Icon_ionic-ios-arrow-dropright2" data-name="Icon ionic-ios-arrow-dropright"
                         xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                        <path id="Path_2325" data-name="Path 2325"
                              d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                              transform="translate(0.327 -1.159)" fill="#fca400"/>
                        <path id="Path_2326" data-name="Path 2326"
                              d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                              transform="translate(-3.375 -3.375)" fill="#fca400"/>
                    </svg>
                    Contentmanager*in (m/w/d)
                </div>
                <div class="page_content-subtitle">
                    <svg id="Icon_ionic-ios-arrow-dropright3" data-name="Icon ionic-ios-arrow-dropright"
                         xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                        <path id="Path_2325" data-name="Path 2325"
                              d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                              transform="translate(0.327 -1.159)" fill="#fca400"/>
                        <path id="Path_2326" data-name="Path 2326"
                              d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                              transform="translate(-3.375 -3.375)" fill="#fca400"/>
                    </svg>
                    Full Stack Developer (m/w/d)
                </div>
                <div class="page_content-subtitle get_right">
                    <svg id="Icon_ionic-ios-arrow-dropright4" data-name="Icon ionic-ios-arrow-dropright"
                         xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                        <path id="Path_2325" data-name="Path 2325"
                              d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                              transform="translate(0.327 -1.159)" fill="#fca400"/>
                        <path id="Path_2326" data-name="Path 2326"
                              d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                              transform="translate(-3.375 -3.375)" fill="#fca400"/>
                    </svg>
                    AWS Cloud & IT Security Engineer (m/w/d)
                </div>
                <div class="mail_subtitle">Schick uns einfach Deinen Lebenslauf und ein Anschreiben an</div>
                <div class="page_content-subtitle get_right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34.178" height="27" viewBox="0 0 34.178 27">
                        <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(-0.911 -4.5)">
                            <path id="Path_2327" data-name="Path 2327"
                                  d="M6,6H30a3.009,3.009,0,0,1,3,3V27a3.009,3.009,0,0,1-3,3H6a3.009,3.009,0,0,1-3-3V9A3.009,3.009,0,0,1,6,6Z"
                                  fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="3"/>
                            <path id="Path_2328" data-name="Path 2328" d="M33,9,18,19.5,3,9" fill="none"
                                  stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        </g>
                    </svg>
                    jobs@intercontent.de
                </div>
            </div>
        </div>
        <div class="scroll_content">
            <div class="scroll_text">Scroll Down</div>
            <div class="scroll_img"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Carrier",
        data() {
            return {
                tick: -150,
                halfTick: 100,
                oneTick: 1
            }
        },
        methods: {
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        deltaY: 300
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        deltaY: -300
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                let circle = this.$refs['circle_blue']
                let page_content = this.$refs['page_content']
                let arrows = this.$refs['arrows']
                let get_right = document.getElementsByClassName('get_right')
                this.tick += event.deltaY * 2
                this.oneTick += event.deltaY * 0.03
                this.halfTick += event.deltaY
                circle.style.left = this.tick + 'px';
                if (this.tick >= 1250) {
                    this.tick = 1250
                    // page_content.style.opacity = 0
                    // page_content.style.visibility = 'hidden'
                    // page_content.style.width = 0
                    page_content.style.display = 'none'
                    // arrows.style.opacity = 1
                    // arrows.style.visibility = 'visible'
                    // arrows.style.width = '100%'
                    arrows.style.display = 'flex'
                    // get_right[0].style.transition = '2s ease-in-out';
                    //
                    // get_right[0].style.transform = `translateX(${this.halfTick}px)`;

                }
                if (this.oneTick >= 33) {
                    for (let i = 0; i < get_right.length; i++) {
                        get_right[i].style.transform = `translateX(100px)`;
                    }
                    circle.style.transform = "scale(" + 3 + ")";
                }

                if (this.oneTick >= 60) {
                    this.$router.push('/contact')
                }
                if (this.oneTick < 1) {
                    this.$router.push('/wir_sind')
                }
            }
        },
        mounted() {
            document.addEventListener("wheel", this.handleScroll,);
            this.$store.commit('SHOW_LOADER', false)
        },
        destroyed() {
            document.removeEventListener("wheel", this.handleScroll,);
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            z-index: 9;
            background-image: url("~@/assets/images/workers.png");
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }

            & .gradient_layer {
                position: absolute;
                width: 100%;
                height: 100vh;
                background: transparent linear-gradient(132deg, #1B224A 0%, #1D4167 42%, #218FAE 80%, #1B224A 100%) 0% 0% no-repeat padding-box;
                opacity: 0.5;
            }

            & .grey_circle {
                position: absolute;
                bottom: -75px;
                left: -150px;
                border-radius: 50%;
                width: 750px;
                height: 750px;
                background: #1D53A3;
                opacity: 0.5;
                z-index: -1;
                transition: .8s ease-in-out;
            }

            &-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                }
            }

            &-data {
                display: block;
                transition: .5s ease-in-out;
                max-width: 870px;

                &.arrows {
                    max-width: 1130px;
                    display: none;
                    flex-direction: column;
                    transition: .5s ease-in-out;
                }

                & .mail_subtitle {
                    color: #A9D1DC;
                    font-size: 2.063rem;
                    font-weight: 500;
                    @media screen and (max-width: 1440px) {
                        font-size: 1.2rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 0.625rem;
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 0.875rem;
                    }
                }
            }

            &-title {
                color: #fff;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 50px;
                @media screen and (max-width: 1440px) {
                    font-size: 2rem;
                    margin-bottom: 2.5rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                    max-width: 360px;
                    margin-left: 1rem;
                }
            }

            &-subtitle {
                max-width: 870px;
                width: 100%;
                color: #fff;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 2.5rem;
                transition: .5s ease-in-out;
                @media screen and (max-width: 1440px) {
                    font-size: 1rem;
                    padding-bottom: 1.25rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 550px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 500px;
                    font-weight: 400;
                    padding-left: 1rem;
                }

                & svg {
                    margin-right: 1.75rem;
                    @media screen and (max-width: 480px) {
                        margin-right: 0.938rem;
                        width: 28px;
                        height: 28px;
                    }

                }

                &.get_right {
                    transition: 2s ease-in-out;
                }
            }

            & .scroll {
                &_content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    bottom: 40px;
                }

                &_text {
                    color: #fff;
                    font-size: 0.875rem;
                    margin-bottom: 5px;
                }

                &_img {
                    width: 26px;
                    height: 38px;
                    border-radius: 12px;
                    border: 1px solid #fff;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        top: 0;
                        left: 6px;
                        background: url("~@/assets/images/arrow_down_white.svg") no-repeat center;
                        background-size: contain;
                        animation: mymove 2s infinite;
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
    }

    @keyframes mymove {
        from {
            top: 0;
        }
        to {
            top: 20px;
        }
    }
</style>