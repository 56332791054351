<template>
    <div class="bar_content" :class="[{'no_transparent': currentRouteName === 'Advertiser'}, {'no_transparent': currentRouteName === 'Carrier'}]">
        <div class="bar_logo" @click="homeLink()" :class="[{'light_logo': currentRouteName === 'Advertiser'}, {'light_logo': currentRouteName === 'Carrier'}]">
        </div>
        <div class="bar_links">
            <ul :class="[{'light_mode': currentRouteName === 'Advertiser'}, {'light_mode': currentRouteName === 'Carrier'}]">
                <router-link to="/" :class="{'active_tab': currentRouteName === 'Publisher'}">
                    <li @click="moveToPublisher()">Publisher</li>
                </router-link>
                <router-link to="/" :class="{'active_tab': currentRouteName === 'Advertiser'}">
                    <li @click="moveToAdvertiser()">Advertiser</li>
                </router-link>
                <router-link to="/" :class="{'active_tab': currentRouteName === 'WirSind'}">
                    <li @click="moveToWir()">Wir sind</li>
                </router-link>
                <router-link to="/" :class="{'active_tab': currentRouteName === 'Carrier'}">
                    <li @click="moveToKareer()">Karriere</li>
                </router-link>
                <router-link to="/" :class="{'active_tab': currentRouteName === 'Contact'}">
                    <li @click="moveToContact()">Kontakt</li>
                </router-link>
            </ul>
            <div :class="[{'dark_mode': currentRouteName === 'Advertiser'}, {'dark_mode': currentRouteName === 'Carrier'}]">
                <div class="burger-menu" :class="{'active': active}" @click="onBurgerClicked()">
                    <div class="burger-container">
                        <div class="burger-inner"></div>
                        <div class="menu_content" :class="{'show': active}">
                            <div class="links" >
                                <router-link to="/">
                                    <div class="link_item" @click="moveToPublisher()">Publisher</div>
                                </router-link>
                                <router-link to="/">
                                    <div class="link_item" @click="moveToAdvertiser()">Advertiser</div>
                                </router-link>
                                <router-link to="/">
                                    <div class="link_item" @click="moveToWir()">Wir sind</div>
                                </router-link>
                                <router-link to="/">
                                    <div class="link_item" @click="moveToKareer()">Karriere</div>
                                </router-link>
                                <router-link to="/">
                                    <div class="link_item" @click="moveToContact()">Kontakt</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                active: false
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            homeLink() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                this.$store.commit('ROUT_TO', 'home')
            },
            moveToPublisher() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                setTimeout(() => {
                    this.$store.commit('ROUT_TO', 'publisher')
                }, 1000)
            },
            moveToAdvertiser() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                setTimeout(() => {
                    this.$store.commit('ROUT_TO', 'advertiser')
                }, 1000)
            },
            moveToWir() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                setTimeout(() => {
                    this.$store.commit('ROUT_TO', 'wir')
                }, 1000)
            },
            moveToKareer() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                setTimeout(() => {
                    this.$store.commit('ROUT_TO', 'career')
                }, 1000)
            },
            moveToContact() {
                if(this.currentRouteName !== 'Home') {
                    this.$router.push('/')
                }
                setTimeout(() => {
                    this.$store.commit('ROUT_TO', 'contact')
                }, 1000)
            },
            onBurgerClicked() {
                this.active = !this.active;
            },

        }
    }
</script>

<style scoped lang="scss">
.bar {
    &_content {
        position: fixed;
        top: 0;
        /*max-width: 1640px;*/
        padding: 25px 100px;
        width: 100%;
        height: 160px;
        background: transparent linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0.9) 50%, transparent 100% );
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 20
    ;
        @media screen and (max-width: 1280px) {
            height: 150px;
        }
        &.no_transparent {
            background: transparent;
        }
        @media screen and (max-width: 1280px) {
            padding: 20px 60px;
        }
        @media screen and (max-width: 480px) {
            padding: 0 20px;
            height: 115px;
        }

    }
    &_logo {
        width: 190px;
        height: 118px;
        background: url("~@/assets/images/logo_head.png") no-repeat center;
        background-size: cover;
        cursor: pointer;
        @media screen and (max-width: 1280px) {
            width: 160px;
            height: 100px;
        }
        @media screen and (max-width: 480px) {
            width: 120px;
            height: 80px;
        }
        &.light_logo {
            background: url("~@/assets/images/logo_light.svg") no-repeat center;
            background-size: cover;
            @media screen and (max-width: 1280px) {
                width: 160px;
                height: 100px;
            }
            @media screen and (max-width: 480px) {
                width: 120px;
                height: 80px;
            }
        }
    }
    &_links {
        z-index: 10;
        & .light_mode {
            a {
                li {
                    color: #fff;
                }
                &:after {
                    background-color: #fff;
                }
            }
        }
        & ul {
            display: flex;
            list-style-type: none;
            @media screen and (max-width: 768px) {
                display: none;
            }
            & a {
                text-decoration: none;
                position: relative;
                margin: 0 30px;
                @media screen and (max-width: 1280px) {
                    margin: 0 25px;
                }
                &.active_tab {
                    & li {
                        color: #FCA400;
                    }
                    &:after {
                        background-color: #FCA400;
                    }
                }
                &:after {
                    position: absolute;
                    content: "";
                    display: block;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: #1B224A;
                    transition: transform .35s;
                    transition-delay: 0s;
                    transition-timing-function: ease;
                    transform: scaleX(0);
                }
                &:hover {
                    &:after {
                        width: 100%;
                        transform: scaleX(1);
                    }
                }
            }
            & li {
                padding: 0 2px;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.125rem;
                cursor: pointer;
                @media screen and (max-width: 1280px) {
                    font-size: 1rem;
                }
            }
        }
    }
}
$burgerWidth: 24px;
$burgerBarHeight: 1px;
$burgerBarColor: #064E82;

.burger-menu {
    width: $burgerWidth;
    height: $burgerWidth;
    cursor: pointer;
    margin: 0 30px;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        margin: 0 0 0 30px;
    }
    @media screen and (max-width: 425px) {
        margin: 0 0 0 20px;
    }
    @media screen and (max-width: 320px) {
        margin: 0 0 0 15px;
    }

    .burger-container {
        position: relative;
        .menu_content {
            position: absolute;
            width: 200px;
            background: transparent;
            /*border: 1px solid #409edc;*/
            /*padding: 20px 0;*/
            top: 40px;
            right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transform: translateX(50px);
            visibility: hidden;
            opacity: 0;
            transition: .4s ease-in-out;
            z-index: 20;
            & .user_logo {
                width: 100%;
                height: 50px;
                & .user_img {
                    & path {
                        fill: #000;
                    }
                }
                & span {
                    font-family: 'Chakra Petch', sans-serif;
                    margin-left: 15px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                }
                & img {
                    width: 35px;
                    height: 35px;
                    margin: 0 15px;
                    border-radius: 50%;
                }
            }
            & .mobile_logOut {
                width: 100%;
                text-align: center;
                font-family: 'Chakra Petch', sans-serif;
                color: #707070;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
            }
            & .links {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                background: transparent;
                box-shadow: -8px 25px 43px -18px rgba(0,0,0,0.28);
                & a {
                    color: $burgerBarColor;
                }
            }
            & .link_item {
                width: 140px;
                padding: 10px 30px;
                font-weight: 500;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                /*background: rgb(255,255,255);*/
                /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(64,158,220,1) 100%);*/
                background: #fff;
                /*background: linear-gradient(90deg, rgba(255, 255, 255, 0.95) 0%, rgb(255, 255, 255) 100%);*/
                /*box-shadow: 2px 2px 3px 10px #888;*/
            }
        }
        .show {
            transform: translateX(0px);
            visibility: visible;
            opacity: 1;
        }

        .burger-inner {
            position: absolute;
            width: $burgerWidth - 2 * $burgerBarHeight;
            left: 0;
            top: 11px;
            border: $burgerBarHeight solid $burgerBarColor;
            transition: border-color .2s ease-in .2s;

            &::before {
                position: absolute;
                content: '';
                top: -6px;
                left: -$burgerBarHeight;
                width: $burgerWidth - 2 * $burgerBarHeight;
                border: $burgerBarHeight solid $burgerBarColor;
                transition: top .2s ease-in .3s, transform ease-in .2s;
            }

            &::after {
                position: absolute;
                content: '';
                top: 4px;
                left: -$burgerBarHeight;
                width: $burgerWidth - 2 * $burgerBarHeight;
                border: $burgerBarHeight solid $burgerBarColor;
                transition: top .2s ease-in .3s, transform ease-in .2s;
            }
        }
    }

    &.active .burger-container .burger-inner {
        border-color: transparent !important;
        transition: border-color ease-in .1s;
    }

    &.active .burger-container .burger-inner::before {
        top: -$burgerBarHeight;
        transform: rotate(-45deg);
        transition: top ease-out .2s, transform .2s ease-out .3s;
    }

    &.active .burger-container .burger-inner::after {
        top: -$burgerBarHeight;
        transform: rotate(45deg);
        transition: top ease-out .2s, transform .2s ease-out .3s;
    }
}
     .dark_mode {
        & .burger-inner {
            border: 1px solid #fff !important;
            &:before {
                border: 1px solid #fff !important;
            }
            &:after {
                border: 1px solid #fff !important;
            }
        }
    }
</style>