import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import  'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents, {
  swipeTolerance: 90,
})
Vue.config.productionTip = false
window.alert = new Vue();
new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
