<template>
  <div id="app" :class="{'position_none': currentRouteName === 'DataProtection'}" >
    <Loader v-show="$store.state.isLoading"/>
    <NavBar />
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>

  </div>
</template>
<script>

  import NavBar from "./components/NavBar";
  import Loader from "./components/Loader"
  export default {
    name: 'App',
    data() {
      return {
        positionStatus: false,
        transform: 0
      }
    },
    components: {
      NavBar,
      Loader
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
      x () {
        return `transform: translateY(${ this.transform }px)`
      }
    },
    watch:{
      positionStatus:{
        handler(newVal){
          // this.positionStatus = newVal;
          this.transform = newVal;
        },
        deep:true
      }
    },
    created() {
      alert.$on("position",data =>{
        // this.positionStatus = data.status;
        console.log(data, 'data')
        this.transform = data.transform;
      })
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
  #app {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*position: fixed;*/
    &.position_none {
      position: unset !important;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.7s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0.3;
    transition:  0.5s ease-in-out;
  }
  body {
    overscroll-behavior: contain;
  }
  html {
    scroll-behavior: smooth;
  }

</style>
