import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/publisher',
    name: 'Publisher',
    component: () => import(/* webpackChunkName: "publisher" */ '../views/Publisher')
  },
  {
    path: '/advertiser',
    name: 'Advertiser',
    component: () => import(/* webpackChunkName: "advertiser" */ '../views/Advertiser')
  },
  {
    path: '/wir_sind',
    name: 'WirSind',
    component: () => import(/* webpackChunkName: "wir_sind" */ '../views/WirSind')
  },
  {
    path: '/karriere',
    name: 'Carrier',
    component: () => import(/* webpackChunkName: "carrier" */ '../views/Carrier')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact')
  },

  {
    path: '/datenschutz',
    name: 'DataProtection',
    component: () => import(/* webpackChunkName: " dataProtection" */ '../views/DataProtection')
  },
  {
    path: '/impressum',
    name: 'Prints',
    component: () => import(/* webpackChunkName: " prints" */ '../views/Prints')
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  store.commit('SHOW_LOADER', true)
  return next()
});

export default router
