<template>
    <div>
        <div class="loader">
            <div class="loader_layer">
                <div class="loader_circle rotating"></div>
                <div class="loader_text"></div>
                <div class="snow"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped lang="scss">
    $d: 3; // density
    $w: 700; // grid size
    $s: 2s; // speed

    // Generate the snow image using radial gradients
    $grad: ();
    @for $i from 0 to $d {
        $v: random(6) + 2;
        $a: random(5) * .1 + .5;
        $grad: $grad, radial-gradient(
                        $v+px $v+px at (random($w - $v * 2) + $v)+px (random($w - $v * 2) + $v)+px,
                        rgba(0, 218, 245, $a) 60%,
                        rgba(3, 3, 7, 0)
        ) !global;
    }

    .loader {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 30;
        background: url("~@/assets/images/technology_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .loader_layer {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            & .loader_circle {
                position: absolute;
                top: calc(50% - 100px);
                left: calc(50% - 100px);
                width: 200px;
                height: 200px;
                background: url("~@/assets/images/logo_circle.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: 15;
            }

            & .loader_text {
                position: absolute;
                top: calc(50% - 75px);
                left: calc(50% - 145px);
                width: 300px;
                height: 150px;
                background: url("~@/assets/images/logo_text.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: 20;
            }

            .snow, .snow:before, .snow:after {
                position: absolute;
                top: -$w + px;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: $grad;
                background-size: $w + px $w + px;
                animation: snow $s linear infinite;
                content: "";
            }

            .snow:after {
                margin-left: -$w/3 + px;
                opacity: .4;
                animation-duration: $s*2;
                animation-direction: reverse;
                filter: blur(3px);
            }

            .snow:before {
                animation-duration: $s*3;
                animation-direction: reverse;
                margin-left: -$w/2 + px;
                opacity: .65;
                filter: blur(1.5px);
            }

            @keyframes snow {
                to {
                    transform: translateX($w + px);
                }
            }
        }
    }
</style>