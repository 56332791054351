<template>
    <div
        class="page_content"
        v-touch:swipe="swipeHandler"
        id="overflow_container"
        ref="overflow_container_ref"
    >
<!--        :style="x"-->
<!--home start-->
        <section>
            <div class="page_content-home-parts">
                <div class="grey_circle" ref="circle_grey"></div>
                <div class="page_content-home-data" ref="title_grey">
                    <div class="page_content-home-title">Smarte Technologie</div>
                    <div class="page_content-home-subtitle">Wir sind führend in automatisierten Services zur Vermittlung zwischen
                        Publishern und Affiliate Netzwerken.
                    </div>
                    <div class="page_content-home-btn">
                        <button class="btn orange_btn" @click="toPublisher()">Finde heraus, wieso</button>
                    </div>
                </div>
                <div class="page_content-home-girl">
                    <div class="bag_left" ref="left_bag"></div>
                    <div class="bag_right" ref="right_bag"></div>
                </div>
            </div>
        </section>
<!--home end-->

        <div class="scroll_content">
            <div class="scroll_text">Scroll Down</div>
            <div class="scroll_img"></div>
        </div>

<!--publisher start-->
        <section id="publisher_section">
            <div class="page_content-publisher-parts">
                <!--            <div class="grey_circle" ref="circle_grey"></div>-->
                <div class="page_content-publisher-data">
                    <div class="page_content-publisher-title">Langjährige Erfahrung</div>
                    <div class="page_content-publisher-subtitle">
                        Mit mehr als 22 Jahren Erfahrung hat sich <span>Intercontent </span> zu einer führenden Technologieplattform für
                        Publisher auf dem deutschen Markt mit hohem technischen Lock-in entwickelt. Unsere Technologie wird
                        bereits weltweit eingesetzt, indem wir jeden Monat mehr als 3,5 Millionen Klicks automatisiert
                        kanalisieren.
                    </div>
                </div>
                <div class="page_content-publisher-img" ref="publisher_img"></div>
            </div>
        </section>
<!--publisher end-->

<!--advertiser start-->
        <div class="adv_container" id="advertiser_section">
            <div class="page_content-advertiser-parts" ref="content_parts">
                <div class="advertiser_container">
                    <div class="page_content-advertiser-data" ref="page_data">
                        <div class="page_content-advertiser-title" ref="advertiser_title">Vertrauensvolle Partnerschaft</div>
                        <div class="page_content-advertiser-subtitle" ref="advertiser_sub">Wir pflegen sowohl mit den wichtigsten
                            Netzwerken als auch E-Commerce Unternehmen intensive und vertrauensvolle Partnerschaften. Das
                            funktioniert nur mit herausragendem ROI, den wir erzielen.
                        </div>
                    </div>
                    <div class="page_content-advertiser-girl" ref="advertiser_img"></div>
                </div>
            </div>
        </div>
<!--advertiser end-->

<!--wir sind start-->
        <div class="page_content-wir-sind-parts content_break" ref="page_content_parts" id="wir_section">
            <div class="page_content-wir-sind-img" ref="wir_sind_img"></div>
            <div class="page_content-wir-sind-data" ref="page_data_man">
                <div class="page_content-wir-sind-title">Signifikanter Unterschied</div>
                <div class="page_content-wir-sind-subtitle">Wir nutzen uns zur Verfügung stehenden qualitativ hochwertigen
                    Traffic zur Monetarisierung und steigern nicht nur die KPI unserer Partner, sondern erzielen sofort
                    Umsatzsteigerungen.
                </div>
            </div>
        </div>
<!--wir sind end-->

<!--wir start-->
        <div class="page_content-wir-parts">
            <div class="page_content-wir-data" ref="wir_sind_data">
                <div class="page_content-wir-title">Wir</div>
                <ul class="page_content-wir-subtitle subtitle_ul" ref="sub_ul">
                    <li class="subtitle_item" style="--animation-order: 1;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        VERARBEITEN >3.5 MILLION KLICKS / MONAT
                    </li>
                    <li class="subtitle_item" style="--animation-order: 2;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        SIND PARTNER VON 20+ NETZWERKE
                    </li>
                    <li class="subtitle_item" style="--animation-order: 3;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        PRÄSENTIEREN PRODUKTE VON >1,000 MERCHANTS
                    </li>
                    <li class="subtitle_item" style="--animation-order: 4;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        UNSERE SERVICES SIND WELTWEIT AUSGEROLLT
                    </li>
                    <li class="subtitle_item" style="--animation-order: 5;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        HABEN EINE SICHTBARKEIT VON 90 % DER ANDROID-NUTZER
                    </li>
                </ul>
            </div>
            <div class="page_content-wir-img" ref="wir_img"></div>
        </div>
<!--wir end-->

<!--karriere start-->
        <div class="career_container" id="career_section">
            <div class="page_content-career-parts">
                <div class="career_content">
                    <div class="page_content-career-data" ref="career_data">
                        <div class="page_content-career-title" ref="career_title">Wenn auch Du zu unserem Team gehören und Teil unseres Erfolges sein
                            möchtest
                        </div>
                        <div class="page_content-career-subtitle" ref="career_sub">Bewirb dich jetzt! Zurzeit suchen wir in unserem Büro am Kudamm in
                            Berlin:
                        </div>
                    </div>
                    <div class="page_content-career-data arrows" ref="arrows">
                        <div class="page_content-career-subtitle">
                            <svg id="Icon_ionic-ios-arrow-dropright1" data-name="Icon ionic-ios-arrow-dropright"
                                 xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                                <path id="Path_2325" data-name="Path 2325"
                                      d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                                      transform="translate(0.327 -1.159)" fill="#fca400"/>
                                <path id="Path_2326" data-name="Path 2326"
                                      d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                                      transform="translate(-3.375 -3.375)" fill="#fca400"/>
                            </svg>
                            Sales Manager*in (m/w/d)
                        </div>
                        <div class="page_content-career-subtitle get_right">
                            <svg id="Icon_ionic-ios-arrow-dropright2" data-name="Icon ionic-ios-arrow-dropright"
                                 xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                                <path id="Path_2325" data-name="Path 2325"
                                      d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                                      transform="translate(0.327 -1.159)" fill="#fca400"/>
                                <path id="Path_2326" data-name="Path 2326"
                                      d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                                      transform="translate(-3.375 -3.375)" fill="#fca400"/>
                            </svg>
                            Contentmanager*in (m/w/d)
                        </div>
                        <div class="page_content-career-subtitle">
                            <svg id="Icon_ionic-ios-arrow-dropright3" data-name="Icon ionic-ios-arrow-dropright"
                                 xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                                <path id="Path_2325" data-name="Path 2325"
                                      d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                                      transform="translate(0.327 -1.159)" fill="#fca400"/>
                                <path id="Path_2326" data-name="Path 2326"
                                      d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                                      transform="translate(-3.375 -3.375)" fill="#fca400"/>
                            </svg>
                            Full Stack Developer (m/w/d)
                        </div>
                        <div class="page_content-career-subtitle get_right">
                            <svg id="Icon_ionic-ios-arrow-dropright4" data-name="Icon ionic-ios-arrow-dropright"
                                 xmlns="http://www.w3.org/2000/svg" width="39.06" height="39.06" viewBox="0 0 39.06 39.06">
                                <path id="Path_2325" data-name="Path 2325"
                                      d="M14.949,10.511a1.819,1.819,0,0,1,2.563,0L26.47,19.5a1.81,1.81,0,0,1,.056,2.5L17.7,30.848a1.809,1.809,0,1,1-2.563-2.554l7.5-7.615-7.69-7.615A1.791,1.791,0,0,1,14.949,10.511Z"
                                      transform="translate(0.327 -1.159)" fill="#fca400"/>
                                <path id="Path_2326" data-name="Path 2326"
                                      d="M3.375,22.9A19.53,19.53,0,1,0,22.9,3.375,19.527,19.527,0,0,0,3.375,22.9Zm3,0a16.519,16.519,0,0,1,28.206-11.68A16.519,16.519,0,1,1,11.224,34.585,16.383,16.383,0,0,1,6.38,22.9Z"
                                      transform="translate(-3.375 -3.375)" fill="#fca400"/>
                            </svg>
                            AWS Cloud & IT Security Engineer (m/w/d)
                        </div>
                        <div class="mail_subtitle">Schick uns einfach Deinen Lebenslauf und ein Anschreiben an</div>
                        <div class="page_content-career-subtitle get_right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34.178" height="27" viewBox="0 0 34.178 27">
                                <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(-0.911 -4.5)">
                                    <path id="Path_2327" data-name="Path 2327"
                                          d="M6,6H30a3.009,3.009,0,0,1,3,3V27a3.009,3.009,0,0,1-3,3H6a3.009,3.009,0,0,1-3-3V9A3.009,3.009,0,0,1,6,6Z"
                                          fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="3"/>
                                    <path id="Path_2328" data-name="Path 2328" d="M33,9,18,19.5,3,9" fill="none"
                                          stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </g>
                            </svg>
                            jobs@intercontent.de
                        </div>
                    </div>
                </div>
                <div class="page_content-career-img" ref=""></div>
            </div>
        </div>
<!--karriere end-->

<!--contact start-->
        <div class="page_content-contact-parts" id="contact_section">
            <div class="page_content-contact-data">
                <div class="page_content-contact-title">Kontakt</div>
                <div class="page_content-contact-links">
                    <div class="link_item">
                        <svg id="Group_4023" data-name="Group 4023" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4" data-name="Group 4">
                                <g id="Group_2" data-name="Group 2">
                                    <g id="Ellipse_2" data-name="Ellipse 2" fill="none" stroke="#fca400" stroke-width="1">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                    </g>
                                </g>
                            </g>
                            <g id="map-pin" transform="translate(12 10)">
                                <path id="Path_3" data-name="Path 3" d="M21,10c0,7-9,13-9,13S3,17,3,10a9,9,0,1,1,18,0Z" transform="translate(-3 -1)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="3" cy="3" r="3" transform="translate(6 6)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                        </svg>
                        Uhlandstrasse 28, 10719 Berlin
                    </div>
                    <div class="link_item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4021" data-name="Group 4021" transform="translate(0 0.193)">
                                <path id="phone" d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z" transform="translate(8.388 8.5)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0 -0.193)" fill="none" stroke="#fca400" stroke-width="1">
                                    <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                    <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                </g>
                            </g>
                        </svg>
                        (030) 23 63 13 30
                    </div>
                    <div class="link_item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4022" data-name="Group 4022" transform="translate(0 0.386)">
                                <g id="Group_2" data-name="Group 2">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0 -0.386)" fill="none" stroke="#fca400" stroke-width="1">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                    </g>
                                </g>
                                <g id="mail" transform="translate(11 13)">
                                    <path id="Path_1" data-name="Path 1" d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z" transform="translate(-2 -4)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_2" data-name="Path 2" d="M22,6,12,13,2,6" transform="translate(-2 -4)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </g>
                        </svg>
                        info@intercontent.de
                    </div>
                </div>
            </div>
            <div class="page_map"></div>

            <div class="footer_part">
                <div class="links">
                    <router-link to="/impressum">
                        <div class="link_item">Impressum</div>
                    </router-link>
                    <router-link to="/datenschutz">
                        <div class="link_item">Datenschutz</div>
                    </router-link>
                </div>
                <div class="copyright">Copyright 2022 Intercontent GmbH</div>
            </div>
        </div>

<!--contact end-->

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'Home',
        data() {
            return {
                transform: 1,
                tick: 1,
                halfTick: 1,
                oneTick: 1,
                scrollTick: 1,
                publisherTick: 1,
                circleHalfTick: 1,
                advertiserTick: 110,
                wirSindTick: 1,
                careerTick: 150,
                kontactTick: 1,
              circle_tick: 1,
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            }
        },
        created() {
            this.$store.commit('SHOW_LOADER', true)

        },
        watch: {
            toRoute: {
                handler(val) {
                    let top = 80
                    if(val === 'publisher') {
                        // const el = document.getElementById('publisher_section');
                        if(this.height > 1024) {
                            window.scrollTo(0, this.height)
                        } else if(this.height == 1024) {
                            window.scrollTo(0, this.height)
                        } else if(this.height >= 900 && this.height < 1024) {
                            window.scrollTo(0, this.height)
                        } else if(this.height < 900) {
                            if(this.width == 480) {
                                window.scrollTo(0, this.height)
                            }else {
                                window.scrollTo(0, this.height)
                            }
                        }

                    } else if(val === 'advertiser') {
                        // const el = document.getElementById('advertiser_section');
                        if(this.height > 1024) {
                            window.scrollTo(0, this.height * 2)
                        } else if(this.height == 1024) {
                            window.scrollTo(0, this.height * 2)
                        } else if(this.height >= 900 && this.height < 1024) {
                            window.scrollTo(0, this.height * 2)
                        } else if(this.height < 900) {
                            if(this.width == 480) {
                                window.scrollTo(0, this.height * 2)
                            }else {
                                window.scrollTo(0, this.height * 2)
                            }
                        }
                    }
                    else if(val === 'wir') {
                        // const el = document.getElementById('wir_section');
                        if(this.height > 1024) {
                            window.scrollTo(0, this.height * 3)
                        } else if(this.height == 1024) {
                            window.scrollTo(0, this.height * 3)
                        } else if(this.height >= 900 && this.height < 1024) {
                            window.scrollTo(0, this.height * 3)
                        } else if(this.height < 900) {
                            if(this.width == 480) {
                                window.scrollTo(0, this.height * 3)
                            }else {
                                window.scrollTo(0, this.height * 3)
                            }
                        }

                    }
                    else if(val === 'career') {
                        // const el = document.getElementById('career_section');
                        if(this.height > 1024) {
                            window.scrollTo(0, this.height * 5)
                        } else if(this.height == 1024) {
                            window.scrollTo(0, this.height * 4.08)
                        } else if(this.height >= 900 && this.height < 1024) {
                            window.scrollTo(0, this.height * 5)
                        } else if(this.height < 900) {
                            if(this.width == 480) {
                                window.scrollTo(0, this.height * 4.55)
                            }else {
                                window.scrollTo(0, this.height * 4.9)
                            }
                        }
                    } else if(val === 'contact') {
                        // const el = document.getElementById('contact_section');
                        if(this.height > 1024) {
                            window.scrollTo(0, this.height * 6)
                        } else if(this.height == 1024) {
                            window.scrollTo(0, this.height * 5.2)
                        } else if(this.height >= 900 && this.height < 1024) {
                            window.scrollTo(0, this.height * 6)
                        } else if(this.height < 900) {
                            if(this.width == 480) {
                                window.scrollTo(0, this.height * 6)
                            }else {
                                window.scrollTo(0, this.height * 6)
                            }
                        }
                        // this.scrollTick = el.scrollHeight
                        // this.halfTick = el.scrollHeight * 0.2
                        // this.oneTick = el.scrollHeight * 0.03
                        // el.scrollTop = el.scrollHeight * -6
                    } else if(val === 'home') {
                        window.scrollTo(0, 0)
                    }

                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(['toRoute']),
            x () {
                return `transform: translateY(${ this.transform }px)`
            },

        },
        methods: {
            toPublisher() {
                this.$store.commit('ROUT_TO', 'publisher')
                // this.$router.push('/publisher')
            },
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        window: {
                            scrollY: window.scrollY
                        }
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        window: {
                            scrollY: window.scrollY
                        }
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                // console.log()
                // console.log(window.scrollY, 'window.innerWidth')
                // console.log('client', event.clientX, event.clientY, 'layer', event.layerX, event.layerY, 'offset', event.offsetX, event.offsetY,  'event')
                let circle = this.$refs['circle_grey']
                let titleGrey = this.$refs['title_grey']
                let bagLeft = this.$refs['left_bag']
                let bagRight = this.$refs['right_bag']
                let publisher_img = this.$refs['publisher_img']
                let advertiser_img = this.$refs['advertiser_img']
                let advertiser_title = this.$refs['advertiser_title']
                let advertiser_sub = this.$refs['advertiser_sub']
                let wir_sind_img = this.$refs['wir_sind_img']
                let wir_img = this.$refs['wir_img']
                let get_right = document.getElementsByClassName('subtitle_item')
                let get_right_careen = document.getElementsByClassName('get_right')
                let career_data = this.$refs['career_data']
                let arrows = this.$refs['arrows']
                let overflow_container_ref = this.$refs['overflow_container_ref']
                // let career_title = this.$refs['career_title']
                // let career_sub = this.$refs['career_sub']
                // this.scrollTick = window.scrollY

                if(this.height >= 900) {
                    // this.tick += event.deltaY
                    // this.scrollTick += event.deltaY
                    // this.halfTick += event.deltaY * 0.2
                    // this.oneTick += event.deltaY * 0.03
                    // this.transform += -event.deltaY * 0.28
                    if(this.height == 1024) {
                        this.tick = window.scrollY * 2.2
                        this.scrollTick = window.scrollY * 4
                        this.halfTick = window.scrollY * 1.8
                        this.oneTick = window.scrollY * 0.3
                    } else {
                        this.tick = window.scrollY * 2.2
                        this.scrollTick = window.scrollY * 3.5
                        this.halfTick = window.scrollY * 1.8
                        this.oneTick = window.scrollY * 0.3
                    }

                    if(this.scrollTick >= 700) {
                        this.oneTick = 60
                    }
                    if(this.scrollTick >= 200) {
                        titleGrey.style.transform = `translateX(30%)`;
                    } else {
                        titleGrey.style.transform = `translateX(0%)`;
                    }
                    if(this.scrollTick <= 1400) {
                      circle.style.right = this.tick + 'px';
                      circle.style.top = 20 + 'px';
                    }
                    if(this.scrollTick > 1400) {
                      this.circle_tick = window.scrollY * 1.5
                      circle.style.right = this.circle_tick + 'px';
                    }
                    if(this.scrollTick >= 1400 && this.scrollTick <= 1600) {
                        circle.style.top = this.height + 'px';
                        circle.style.right = -75 + 'px';
                        this.tick = -1800
                    }
                    if(this.scrollTick >= 2100) {
                        publisher_img.style.transform = "scale(" + (100 + this.publisherTick) / 100 + ")";
                    }
                    this.publisherTick = window.scrollY * 0.02
                    if(this.scrollTick >= 3500) {
                        this.publisherTick = 17
                    }
                    if(this.scrollTick <= 4600) {
                        circle.style.transform = "scale(" + 1 + ")";
                        circle.style.opacity = '1'
                        circle.style.background =  '#F3F3F3';
                    }
                    if(this.scrollTick >= 4600 && this.scrollTick <= 4900 ) {
                      const height = window.innerHeight
                      circle.style.top = height + 'px';
                    }
                    if(this.scrollTick >= 4900 && this.scrollTick <= 5200) {
                      const height = window.innerHeight
                        circle.style.top = 2.5 * height + 'px';
                        this.tick = 1
                        circle.style.opacity = '0.17'
                        circle.style.background = '#1D53A3'
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 5200) {
                        circle.style.right = 1000 + 'px';
                        circle.style.transform = "scale(" + 2 + ")";
                    }
                    bagLeft.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                    bagRight.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                    if(this.scrollTick >= 5600 && this.scrollTick <= 8050) {
                        this.advertiserTick = window.scrollY * 0.09
                        advertiser_img.style.transform = "scale(" + (100 + this.advertiserTick) / 100 + ")";
                        this.circleHalfTick = 81
                    }
                    if(this.scrollTick >= 6401) {
                        advertiser_title.innerText = 'Preisvergleich und Google Shopping'
                        advertiser_sub.innerText = 'Wir platzieren die Produkte unserer Partner in Preisvergleichen und in der sichtbaren produktbezogenen Suche innerhalb der Google Dienste und bieten mehr Umsatz ohne Aufwand.'
                    } else {
                        advertiser_title.innerText = 'Vertrauensvolle Partnerschaft'
                        advertiser_sub.innerText = 'Wir pflegen sowohl mit den wichtigsten Netzwerken als auch E-Commerce Unternehmen intensive und vertrauensvolle Partnerschaften. Das funktioniert nur mit herausragendem ROI, den wir erzielen.'
                    }
                    if(this.scrollTick >= 6801) {
                        this.advertiserTick = 149
                    }
                    if(this.scrollTick < 9100) {
                      wir_sind_img.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 9100 && this.scrollTick <= 10500) {
                        this.wirSindTick = window.scrollY * 0.01
                        wir_sind_img.style.transform = "scale(" + (100 + this.wirSindTick) / 100 + ")";
                    }
                    if(this.scrollTick == 0) {
                        this.tick = 1
                        circle.style.top = -75 + 'px';
                        circle.style.right = -150 + 'px';
                    }
                  if(this.scrollTick >= 8400 && this.scrollTick <= 8500) {
                    const height = window.innerHeight
                    circle.style.top = 2.5 * height + 'px';
                    this.tick = 1
                    circle.style.opacity = '0.17'
                    circle.style.background = '#1D53A3'
                    this.circleHalfTick = 81
                  }
                  if(this.scrollTick >= 8700 && this.scrollTick <= 8800) {
                    const height = window.innerHeight
                    circle.style.top = 3.5 * height + 'px';
                    this.circleHalfTick = 1
                    this.tick = 1
                    circle.style.opacity = '0.05'
                    circle.style.background = '#FCA400'
                    circle.style.left = 'unset'
                    circle.style.right = '0'
                    circle.style.right = 0 + 'px';
                    circle.style.transform = "scale(" + 1 + ")";
                  }
                  if(this.scrollTick >= 8700) {
                    circle.style.left = 'unset'
                    circle.style.right = '0'
                  }
                  if(this.scrollTick >= 9201) {
                    this.wirSindTick = 17
                  }
                  if(this.scrollTick <= 13101) {
                    let move = 0
                    for (let i = 0; i < get_right.length; i++) {
                        get_right[i].style.transform = `translateX(${move}px)`;
                    }
                    wir_img.style.right = 1 + 'px'
                  }
                  if (this.scrollTick >= 13200) {
                    let move = 0
                    for (let i = 0; i < get_right.length; i++) {
                      get_right[i].style.transform = `translateX(${move}px)`;
                      move += 50
                    }
                    wir_img.style.right = -55 + 'px'
                  }
                  if(this.scrollTick >= 8800 && this.scrollTick <= 14800) {
                    const height = window.innerHeight
                    circle.style.top = 3.5 * height + 'px';
                    circle.style.opacity = '0.05'
                    circle.style.background = '#FCA400'
                    circle.style.transform = "scale(" + 2 + ")";
                  }
                  if(this.scrollTick >= 14701 && this.scrollTick <= 14801) {
                    const height = window.innerHeight
                    circle.style.top = 5.2 * height + 'px';
                    this.tick = 1
                    circle.style.opacity = '0.17'
                    circle.style.background = '#1D53A3'
                    circle.style.right = -75 + 'px'
                    circle.style.transform = "scale(" + 1 + ")";
                  }
                  if(this.scrollTick >= 14801 && this.scrollTick <= 19100) {
                    this.careerTick = window.scrollY  * 0.2
                    circle.style.right =  this.careerTick + 'px';
                    circle.style.transform = "scale(" + 1 + ")";
                  }
                  if(this.scrollTick <= 15950) {
                    career_data.style.display = 'block'
                    arrows.style.display = 'none'
                  }
                  if(this.scrollTick >= 16101) {
                    career_data.style.display = 'none'
                    arrows.style.display = 'block'
                  }
                  if(this.scrollTick <= 16450) {
                    for (let i = 0; i < get_right_careen.length; i++) {
                      get_right_careen[i].style.transform = `translateX(0px)`;
                    }
                  }
                  if(this.scrollTick >= 16501) {
                    for (let i = 0; i < get_right_careen.length; i++) {
                      get_right_careen[i].style.transform = `translateX(80px)`;
                    }
                  }
                  if(this.scrollTick >= 15600 && this.scrollTick <= 17701) {
                    const height = window.innerHeight
                    circle.style.top = 5.2 * height + 'px';
                    circle.style.opacity = '0.17'
                    circle.style.background = '#1D53A3'
                  }
                  if(this.scrollTick >= 17701 && this.scrollTick <= 17801) {
                    const height = window.innerHeight
                    circle.style.top = 6.2 * height + 'px';
                    this.circleHalfTick = 1
                    this.tick = 1
                    circle.style.opacity = '0.05'
                    circle.style.background = '#FCA400'
                    circle.style.left = '80px'
                    circle.style.right = '0'
                    circle.style.right = 0 + 'px';
                    circle.style.transform = "scale(" + 1 + ")";
                  }
                  if(this.scrollTick >= 17801) {
                    this.circleHalfTick += event.deltaY * 0.2
                    this.tick = 1
                    this.careerTick = 80
                    this.kontactTick += event.deltaY * 0.2
                    circle.style.transform = "scale(" + (100 + this.kontactTick) / 100 + ")";
                  }
                  if(this.scrollTick >= 19200) {
                    circle.style.transform = "scale(" + 1.5 + ")";
                    circle.style.right = '1000px'
                  }
                  if(this.scrollTick >= 20400) {
                    this.kontactTick = 41
                    this.scrollTick = 20400
                    const height = window.innerHeight
                    this.transform = -6 * height - 70
                  }

                }
                else if(this.height >= 800 && this.height < 900) {
                    this.tick = window.scrollY * 2.2
                    this.scrollTick = window.scrollY * 4.1
                    this.halfTick = window.scrollY * 1.8
                    this.oneTick = window.scrollY * 0.2
                    if(this.scrollTick >= 600) {
                        this.oneTick = 30
                    }
                    if(this.scrollTick >= 200) {
                        titleGrey.style.transform = `translateX(30%)`;
                    } else {
                        titleGrey.style.transform = `translateX(0%)`;
                    }
                    if(this.scrollTick <= 1400) {
                        circle.style.right = this.tick + 'px';
                        circle.style.top = 20 + 'px';
                    }
                    if(this.scrollTick > 1400) {
                        this.circle_tick = window.scrollY * 1.5
                        circle.style.right = this.circle_tick + 'px';
                    }
                    if(this.scrollTick >= 1400 && this.scrollTick <= 1600) {
                        circle.style.top = this.height + 'px';
                        circle.style.right = -75 + 'px';
                        this.tick = -1800
                    }
                    if(this.scrollTick >= 2100) {
                        publisher_img.style.transform = "scale(" + (100 + this.publisherTick) / 100 + ")";
                    }
                    this.publisherTick = window.scrollY * 0.02
                    if(this.scrollTick >= 3500) {
                        this.publisherTick = 17
                    }
                    if(this.scrollTick <= 4600) {
                        circle.style.transform = "scale(" + 1 + ")";
                        circle.style.opacity = '1'
                        circle.style.background =  '#F3F3F3';
                    }
                    if(this.scrollTick >= 4600 && this.scrollTick <= 4900 ) {
                        const height = window.innerHeight
                        circle.style.top = height + 'px';
                    }
                    if(this.scrollTick >= 4900 && this.scrollTick <= 5200) {
                        const height = window.innerHeight
                        circle.style.top = 2.5 * height + 'px';
                        this.tick = 1
                        circle.style.opacity = '0.17'
                        circle.style.background = '#1D53A3'
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 5200) {
                        circle.style.right = 1000 + 'px';
                        circle.style.transform = "scale(" + 2 + ")";
                    }
                    bagLeft.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                    bagRight.style.transform = "scale(" + (100 + this.oneTick) / 100 + ")";
                    if(this.scrollTick >= 5600 && this.scrollTick <= 8050) {
                        this.advertiserTick = window.scrollY * 0.09
                        advertiser_img.style.transform = "scale(" + (100 + this.advertiserTick) / 100 + ")";
                        this.circleHalfTick = 81
                    }
                    if(this.scrollTick >= 6401) {
                        advertiser_title.innerText = 'Preisvergleich und Google Shopping'
                        advertiser_sub.innerText = 'Wir platzieren die Produkte unserer Partner in Preisvergleichen und in der sichtbaren produktbezogenen Suche innerhalb der Google Dienste und bieten mehr Umsatz ohne Aufwand.'
                    } else {
                        advertiser_title.innerText = 'Vertrauensvolle Partnerschaft'
                        advertiser_sub.innerText = 'Wir pflegen sowohl mit den wichtigsten Netzwerken als auch E-Commerce Unternehmen intensive und vertrauensvolle Partnerschaften. Das funktioniert nur mit herausragendem ROI, den wir erzielen.'
                    }
                    if(this.scrollTick >= 6801) {
                        this.advertiserTick = 149
                    }
                    if(this.scrollTick < 9100) {
                        wir_sind_img.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 9100 && this.scrollTick <= 10500) {
                        this.wirSindTick = window.scrollY * 0.01
                        wir_sind_img.style.transform = "scale(" + (100 + this.wirSindTick) / 100 + ")";
                    }
                    if(this.scrollTick == 0) {
                        this.tick = 1
                        circle.style.top = -75 + 'px';
                        circle.style.right = -150 + 'px';
                    }
                    if(this.scrollTick >= 8400 && this.scrollTick <= 8500) {
                        const height = window.innerHeight
                        circle.style.top = 2.5 * height + 'px';
                        this.tick = 1
                        circle.style.opacity = '0.17'
                        circle.style.background = '#1D53A3'
                        this.circleHalfTick = 81
                    }
                    if(this.scrollTick >= 8700 && this.scrollTick <= 8800) {
                        const height = window.innerHeight
                        circle.style.top = 3.5 * height + 'px';
                        this.circleHalfTick = 1
                        this.tick = 1
                        circle.style.opacity = '0.05'
                        circle.style.background = '#FCA400'
                        circle.style.left = 'unset'
                        circle.style.right = '0'
                        circle.style.right = 0 + 'px';
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 8700) {
                        circle.style.left = 'unset'
                        circle.style.right = '0'
                    }
                    if(this.scrollTick >= 9201) {
                        this.wirSindTick = 17
                    }
                    if(this.scrollTick <= 13101) {
                        let move = 0
                        for (let i = 0; i < get_right.length; i++) {
                            get_right[i].style.transform = `translateX(${move}px)`;
                        }
                        wir_img.style.right = 1 + 'px'
                    }
                    if (this.scrollTick >= 12500) {
                        let move = 0
                        for (let i = 0; i < get_right.length; i++) {
                            get_right[i].style.transform = `translateX(${move}px)`;
                            move += 50
                        }
                        wir_img.style.right = -55 + 'px'
                    }
                    if(this.scrollTick >= 8800 && this.scrollTick <= 14800) {
                        const height = window.innerHeight
                        circle.style.top = 3.5 * height + 'px';
                        circle.style.opacity = '0.05'
                        circle.style.background = '#FCA400'
                        circle.style.transform = "scale(" + 2 + ")";
                    }
                    if(this.scrollTick >= 14701 && this.scrollTick <= 14801) {
                        const height = window.innerHeight
                        circle.style.top = 5.2 * height + 'px';
                        this.tick = 1
                        circle.style.opacity = '0.17'
                        circle.style.background = '#1D53A3'
                        circle.style.right = -75 + 'px'
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 14801 && this.scrollTick <= 19100) {
                        this.careerTick = window.scrollY  * 0.2
                        circle.style.right =  this.careerTick + 'px';
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick <= 15950) {
                        career_data.style.display = 'block'
                        arrows.style.display = 'none'
                    }
                    if(this.scrollTick >= 16051) {
                        career_data.style.display = 'none'
                        arrows.style.display = 'block'
                    }
                    if(this.scrollTick <= 16100) {
                        for (let i = 0; i < get_right_careen.length; i++) {
                            get_right_careen[i].style.transform = `translateX(0px)`;
                        }
                    }
                    if(this.scrollTick >= 16150) {
                        for (let i = 0; i < get_right_careen.length; i++) {
                            get_right_careen[i].style.transform = `translateX(80px)`;
                        }
                    }
                    if(this.scrollTick >= 15600 && this.scrollTick <= 17701) {
                        const height = window.innerHeight
                        circle.style.top = 5.2 * height + 'px';
                        circle.style.opacity = '0.17'
                        circle.style.background = '#1D53A3'
                    }
                    if(this.scrollTick >= 17701 && this.scrollTick <= 17801) {
                        const height = window.innerHeight
                        circle.style.top = 6.2 * height + 'px';
                        this.circleHalfTick = 1
                        this.tick = 1
                        circle.style.opacity = '0.05'
                        circle.style.background = '#FCA400'
                        circle.style.left = '80px'
                        circle.style.right = '0'
                        circle.style.right = 0 + 'px';
                        circle.style.transform = "scale(" + 1 + ")";
                    }
                    if(this.scrollTick >= 17801) {
                        this.circleHalfTick += event.deltaY * 0.2
                        this.tick = 1
                        this.careerTick = 80
                        this.kontactTick += event.deltaY * 0.2
                        circle.style.transform = "scale(" + (100 + this.kontactTick) / 100 + ")";
                    }
                    if(this.scrollTick >= 19200) {
                        circle.style.transform = "scale(" + 1.5 + ")";
                        circle.style.right = '1000px'
                    }
                    if(this.scrollTick >= 19500) {
                        this.kontactTick = 41
                        this.scrollTick = 19500
                        const height = window.innerHeight
                        this.transform = -6 * height - 70
                    }

                }
                // else if(this.height < 800 ) {
                //     this.tick += event.deltaY - 30
                //     this.scrollTick += event.deltaY + 50
                //     this.halfTick += event.deltaY * 0.3
                //     this.oneTick += event.deltaY * 0.04
                //     this.transform += -event.deltaY * 0.4
                // }
                // console.log(this.scrollTick , 'this.scrollTick ')

                // this.scrollToBottom(this.transform)
                // console.log(this.scrollTick, 'this.scrollTick')
                // if(this.transform >= 0) {
                //     this.transform = 0
                // }


              //   if (this.tick <= 50) {
              //       this.tick = 1
              //   }
              //   if (this.halfTick >= 240) {
              //       this.halfTick = 240
              //   } else if (this.halfTick <= 1) {
              //       this.halfTick = 1
              //   }
              //   if (this.oneTick >= 35) {
              //       this.oneTick = 35
              //   } else if (this.oneTick <= 1) {
              //       this.oneTick = 1
              //   }
              //
              // if(this.scrollTick <= -50) {
              //   circle.style.right =  10 + '%';
              //   this.scrollTick = 1
              // }
              // if(this.scrollTick < 1501) {
              //     circle.style.top = -75 + 'px';
              // }
              // if(this.scrollTick >= 1590 && this.scrollTick <= 1645) {
              //     circle.style.top = -75 + 'px';
              //     this.tick = 800
              //     circle.style.right = 'unset';
              // }
              //   if (this.scrollTick >= 1600 && this.scrollTick <= 1700) {
              //       const height = window.innerHeight
              //       circle.style.top = height + 'px';
              //       this.tick = 1
              //   }
              //
              //   if(this.scrollTick >= 1600) {
              //       circle.style.right = this.tick + 'px';
              //       if(this.height >= 900) {
              //           this.publisherTick += event.deltaY * 0.03
              //       } else if(this.height >= 800 && this.height < 900) {
              //           this.publisherTick += event.deltaY * 0.01
              //       }
              //       publisher_img.style.transform = "scale(" + (100 + this.publisherTick) / 100 + ")";
              //   }
              //   if(this.scrollTick >= 2101 && this.height > 900) {
              //       publisher_img.style.transform = "scale(" + (100 + 16) / 100 + ")";
              //       this.publisherTick = 19
              //   }
              //   if(this.scrollTick >= 2101 && this.height == 900) {
              //       this.publisherTick = 17
              //   }
              //   if(this.scrollTick >= 2101 && this.height == 800) {
              //       this.publisherTick = 18
              //   }
              //
              //   if(this.scrollTick <= 5300 && this.scrollTick >= 5200) {
              //       this.tick = 3000
              //       const height = window.innerHeight
              //       circle.style.top =  height + 'px';
              //       circle.style.background =  '#F3F3F3';
              //       circle.style.opacity = '1'
              //       circle.style.left = 'unset'
              //       circle.style.transform = "scale(" + 1 + ")";
              //   }
              //   if(this.scrollTick >= 5300 && this.scrollTick <= 5400) {
              //       const height = window.innerHeight
              //       circle.style.top = 2.5 * height + 'px';
              //       this.tick = 1
              //       circle.style.background = '#1D53A3'
              //       circle.style.opacity = '0.17'
              //   }
              //   if(this.scrollTick >= 5300) {
              //       if(this.height >= 900) {
              //           this.circleHalfTick += event.deltaY * 0.2
              //       } else if(this.height >= 800 && this.height < 900) {
              //           this.circleHalfTick += event.deltaY * 0.3
              //       }
              //       circle.style.left = -150 + 'px';
              //       circle.style.transform = "scale(" + (100 + this.circleHalfTick) / 100 + ")";
              //   }
                if(this.height == 900 && this.scrollTick >=18001) {
                    this.kontactTick = 41
                    this.scrollTick = 18101
                    const height = window.innerHeight
                    this.transform = -5.6 * height - 120
                }
            },

            // scrollToBottom(e) {
                // this.transform = e
                // alert.$emit("position", {status: true, transform: e});
                // let container = this.$el.querySelector("#overflow_container");
                // container.clientHeight
                // const height = window.innerHeight
                // window.scrollTo(0, height)
                // window.scrollByPages(1)
                // container.scrollTop = container.scrollHeight;
                // container.style.transform = `translateY(900px)`;
                // container.scrollTop = container.clientHeight;
                // console.log(container.scrollTop, 'container.scrollTop')
                // console.log(container.clientHeight, '.scrollTop')
                // console.log(window.innerHeight, '.window.innerHeight')
                // setTimeout(() => {
                //     alert.$emit("position", {status: false, top: '900px'});
                // }, 3000)
            // }
        },
        mounted() {
            document.addEventListener("scroll", this.handleScroll,);
            setTimeout(() => {
                this.$store.commit('SHOW_LOADER', false)
            }, 1000)
        },
        destroyed() {
            document.removeEventListener("scroll", this.handleScroll,);
        }
    }
</script>
<style lang="scss">
    .page {
        &_content {
            width: 100%;
            /*height: 100vh;*/
            max-height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: .9s ease-in-out;
            /*overflow-y: scroll;*/
            & .adv_container,
            & .career_container {
                width: 100vw;
            }
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }
            & section {
                max-width: 1640px;
                width: 100%;
                height: 100vh;
                @media screen and (max-width: 1680px) {
                    max-width: 1500px;
                }
            }
            & .advertiser {
                width: 100%;
                height: 100vh;
                /*display: flex;*/
                /*flex-direction: column;*/
                /*align-items: center;*/
                /*justify-content: center;*/
                /*position: relative;*/
                /*overflow: hidden;*/
                /*z-index: 9;*/
                /*background: url("~@/assets/images/background.png");*/
                /*@media screen and (max-width: 768px) {*/
                /*    padding: 0 15px;*/
                /*}*/
            }
            & #publisher_section {
                overflow-y: clip;
                @media screen and (max-width: 1440px) {
                   overflow-y: hidden;
                    max-width: 1400px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1280px;
                }
            }

            &-home-girl {
                width: 500px;
                height: 91vh;
                background: url("~@/assets/images/transparent_girl.png") no-repeat center;
                background-size: cover;
                transform: translateX(-150px);
                position: relative;
                z-index: 2;
                @media screen and (max-width: 1680px) {
                    height: 100vh;

                }
                @media screen and (max-width: 1280px) {
                    width: 430px;
                    height: 82vh;
                    background-size: contain;
                }
                @media screen and (max-width: 1440px) {
                    height: 100vh;
                }
                @media screen and (max-width: 768px) {
                    width: 700px;
                    height: 84vh;
                    background-size: cover;
                    transform: translateX(-25px);
                }
                @media screen and (max-width: 480px) {
                    width: 400px;
                    height: 95vh;
                    position: absolute;
                    background-position: center;
                    transform: translateX(75px);
                }

                & .bag_left {
                    position: absolute;
                    bottom: 70px;
                    left: -130px;
                    width: 360px;
                    height: 300px;
                    background: url("~@/assets/images/bag_left.png") no-repeat center;
                    background-size: contain;
                    transition: 1.8s ease-in-out;
                    @media screen and (max-width: 1280px) {
                        left: -110px;
                    }
                    @media screen and (max-width: 480px) {
                        bottom: 60px;
                        width: 320px;
                        height: 260px;
                        left: -75px;
                    }
                }

                & .bag_right {
                    position: absolute;
                    bottom: 70px;
                    left: 430px;
                    width: 360px;
                    height: 300px;
                    background: url("~@/assets/images/bag_right.png") no-repeat center;
                    background-size: contain;
                    transition: 1.8s ease-in-out;
                    @media screen and (max-width: 1280px) {
                        left: 320px;
                    }
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
            &-publisher-img {
                width: 640px;
                height: 90vh;
                background: url("~@/assets/images/MAN1_iStock-1248770730.png");
                background-repeat: no-repeat;
                //transform: translateX(-100px);
                right: -140px;
                position: relative;
                background-position: inherit;
                background-size: cover;
                transition: 1.8s ease-in-out;
                z-index: 2;
                @media screen and (max-width: 1680px) {
                    width: 45%;
                    height: 83vh;
                }
                @media screen and (max-width: 1440px) {
                    height: 90vh;
                }
                @media screen and (max-width: 768px) {
                    width: 60%;
                    height: 55vh;
                    right: -220px;
                    background-position: center;
                }
                @media screen and (max-width: 480px) {
                    width: 60%;
                    height: 55vh;
                    right: -80px;
                }
            }
            &-advertiser-girl {
                position: absolute;
                right: 140px;
                width: 320px;
                height: 435px;
                background: url("~@/assets/images/woman1_iStock-1281083606.png");
                background-repeat: no-repeat;
                background-position: inherit;
                background-size: cover;
                transition: 1.5s ease-in-out;
                transform: scale(2);
                @media screen and (max-width: 1280px) {
                    width: 300px;
                    height: 50vh;
                    background-size: contain;
                }
                @media screen and (max-width: 768px) {
                    width: 200px;
                    height: 35vh;
                    background-size: cover;
                }
                @media screen and (max-width: 480px) {
                    width: 150px;
                    height: 40vh;
                    /*top: 10px;*/
                    background-position: bottom;
                    background-size: contain;
                    right: 50px;
                    /*max-width: 260px;*/
                }
            }
            &-wir-sind-img {
                /*display: none;*/
                width: 720px;
                height: 100vh;
                background: url("~@/assets/images/black_male.png");
                background-repeat: no-repeat;
                background-position: top;
                background-size: cover;
                z-index: 1;
                transition: 1.8s ease-in-out;
                @media screen and (max-width: 1680px) {
                    width: 720px;
                }
                @media screen and (max-width: 1680px) {
                    width: 700px;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    height: 45vh;
                    background-position: right;
                    background-size: contain;
                    position: absolute;
                    margin-top: 1.25rem;
                }
                @media screen and (max-width: 480px) {
                    width: 520px;
                    height: 75vh;
                    left: 55px;
                }
            }
            &-wir-img {
                width: 760px;
                height: 100vh;
                background: url("~@/assets/images/woman2_iStock-1137969301.png");
                background-repeat: no-repeat;
                right: 40px;
                position: relative;
                background-position: center;
                background-size: cover;
                transition: 1.8s ease-in-out;
                /*transform: scale(1.8);*/
                z-index: 2;
                @media screen and (max-width: 1440px) {
                    width: 840px;
                }
                @media screen and (max-width: 1280px) {
                    width: 660px;
                    height: 90vh;
                }
                @media screen and (max-width: 768px) {
                    width: 600px;
                    height: 55vh;
                    background-position: bottom;
                    background-size: contain;
                }
                @media screen and (max-width: 480px) {
                    position: absolute;
                    width: 470px;
                    height: 75vh;
                    background-position: bottom;
                    background-size: contain;
                    opacity: 0;
                    visibility: hidden;
                    right: -200px;
                }
            }
            &-career-img {
                width: 650px;
                height: 100vh;
                background: url("~@/assets/images/coworker_iStock-986662156_1.png");
                background-repeat: no-repeat;
                right: 40px;
                position: relative;
                background-position: center;
                background-size: cover;
                transition: 1.8s ease-in-out;
                /*transform: scale(1.8);*/
                z-index: 2;
                @media screen and (max-width: 768px) {
                    width: 400px;
                    height: 70vh;
                }
                @media screen and (max-width: 480px) {
                    position: absolute;
                    width: 370px;
                    height: 90vh;
                    background-position: top;
                    background-size: contain;
                    /*opacity: 0;*/
                    /*visibility: hidden;*/
                    right: 0;
                    z-index: 1;
                }
            }

            &-home-data {
                transition: .9s ease-in-out;
                z-index: 4;
                @media screen and (max-width: 480px) {
                    margin-bottom: 50px;
                }
            }
            &-publisher-data {
                transition: .9s ease-in-out;
                margin-top: 2.5rem;
                z-index: 4;

                @media screen and (max-width: 1440px) {
                    margin-left: 6.25rem;
                }
                @media screen and (max-width: 768px) {
                    margin-left: 3.25rem;
                    margin-top: 0;
                }
                @media screen and (max-width: 480px) {
                    margin-left: 1.25rem;
                    margin-top: 50px;
                }
            }
            &-advertiser-data {
                transition: .9s ease-in-out;
                @media screen and (max-width: 480px) {
                    margin-right: 175px;
                    margin-bottom: 1.25rem;
                }
            }
            &-wir-sind-data {
                opacity: 1;
                visibility: visible;
                width: 580px;
                z-index: 10;
                @media screen and (max-width: 1440px) {
                    margin-top: 2.5rem;
                    display: flex;
                    flex-direction: column;
                    height: 80vh;
                    justify-content: center;
                }
                @media screen and (max-width: 768px) {
                    width: 500px;
                    height: 50vh;
                }
                @media screen and (max-width: 480px) {
                    margin-top: 3.125rem;
                    padding-left: 1.25rem;
                }
            }
            &-wir-data {
                transition: .9s ease-in-out;
                /*opacity: 0;*/
                /*visibility: hidden;*/
                width: 60%;
                @media screen and (max-width: 1440px) {
                    margin-top: 1.875rem;
                    display: flex;
                    flex-direction: column;
                    height: 80vh;
                    justify-content: center;
                }
                @media screen and (max-width: 768px) {
                    height: 50vh;
                    padding-left: 3.125rem;
                }
                @media screen and (max-width: 480px) {
                    transform: translateY(-55%);
                    height: 45vh;
                    padding-left: 1.25rem;
                }
            }
            &-career-data {
                display: block;
                transition: .5s ease-in-out;
                max-width: 870px;
                @media screen and (max-width: 768px) {
                    max-width: 350px;
                    padding-left: 3.125rem;
                }
                @media screen and (max-width: 480px) {
                    padding-left: 1.25rem;
                    max-width: 300px;
                }

                &.arrows {
                    max-width: 1130px;
                    display: none;
                    flex-direction: column;
                    transition: .5s ease-in-out;
                    @media screen and (max-width: 768px) {
                        max-width: 400px;
                        z-index: 2;
                    }
                }

                & .mail_subtitle {
                    color: #A9D1DC;
                    font-size: 2.063rem;
                    font-weight: 500;
                    @media screen and (max-width: 1440px) {
                        font-size: 1.2rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 0.625rem;
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 0.875rem;
                    }
                }
            }
            &-contact-data {
                @media screen and (max-width: 1440px) {
                    margin-top: 1.25rem;
                }
                @media screen and (max-width: 768px) {
                    margin-top: 0;
                }
                @media screen and (max-width: 480px) {
                    margin-bottom: 0;
                }
            }

            &-home-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 50px;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 760px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                    height: 80vh;
                }
                & .grey_circle {
                    position: absolute;
                    top: -75px;
                    right: -150px;
                    border-radius: 50%;
                    width: 750px;
                    height: 750px;
                    background: #F3F3F3;
                    z-index: 1;
                    transition: .8s ease-in-out;
                    @media screen and (max-width: 1280px) {
                        width: 650px;
                        height: 650px;
                        right: -80px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 500px;
                        height: 500px;
                        right: -100px;
                    }
                    @media screen and (max-width: 480px) {
                        width: 450px;
                        height: 450px;
                    }
                }
            }
            &-publisher-parts {
                padding-top: 100px;
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 84vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1080px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1080px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                    flex-direction: column-reverse;
                    align-items: baseline;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                }

                & .grey_circle {
                    position: absolute;
                    top: -75px;
                    right: -150px;
                    border-radius: 50%;
                    width: 750px;
                    height: 750px;
                    background: #F3F3F3;
                    z-index: 1;
                    transition: .8s ease-in-out;
                    @media screen and (max-width: 1280px) {
                        width: 650px;
                        height: 650px;
                    }

                    @media screen and (max-width: 768px) {
                        width: 500px;
                        height: 500px;
                        right: -100px;
                    }
                    @media screen and (max-width: 480px) {
                        width: 450px;
                        height: 450px;
                    }
                }
            }
            &-advertiser-parts {
                //margin-top: 80px;
                /*max-width: 1640px;*/
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                padding: 0 100px;
                overflow: hidden;
                background: url("~@/assets/images/background.png");
                @media screen and (max-width: 1680px) {
                    max-width: 1680px;
                    //margin-top: 30px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 100vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1280px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 768px;
                    padding: 0 50px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 480px;

                }
                & .advertiser_container {
                    max-width: 1640px;
                    display: flex;
                }
            }
            &-wir-sind-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                transition: 1.8s ease-in-out;
                overflow-y: clip;
                @media screen and (max-width: 1680px) {
                    max-width: 1500px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                    flex-direction: initial;
                    align-items: flex-start;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                    align-items: center;
                    margin-bottom: 250px;
                }
                &.content_break {
                    @media screen and (max-width: 480px) {
                        flex-direction: column;
                        align-items: flex-start;
                        max-height: 100vh;
                        padding: 75px 0;
                    }

                }
            }
            &-wir-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                transition: 1.8s ease-in-out;
                @media screen and (max-width: 1680px) {
                    max-width: 1500px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 768px;
                    flex-direction: initial;
                    align-items: flex-start;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                    align-items: center;
                }
                &.content_break {
                    @media screen and (max-width: 480px) {
                        flex-direction: column;
                        align-items: flex-start;
                        max-height: 100vh;
                        padding: 75px 0;
                    }

                }
            }
            &-career-parts {
                /*max-width: 1640px;*/
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding: 0 100px;
                overflow: hidden;
                background: url("~@/assets/images/background.png");
                @media screen and (max-width: 1680px) {
                    max-width: 1680px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 100vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1280px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 768px;
                    padding: 0;
                }
                @media screen and (max-width: 480px) {
                    max-width: 480px;
                    align-items: end;
                    padding-bottom: 100px;
                }
                & .career_content {
                    z-index: 2;
                }
            }
            &-contact-parts {
                max-width: 1640px;
                width: 100%;
                padding-top: 4rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                    padding-bottom: 100px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                    padding-bottom: 0;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                    flex-direction: column;
                    justify-content: unset;
                    padding-bottom: 6.25rem;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                    padding-bottom: 0;
                }
                & .page_map {
                    width: 1200px;
                    height: 630px;
                    background: url("~@/assets/images/map_z.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    z-index: 1;
                    margin-bottom: 150px;
                    margin-top: 6.25rem;
                    @media screen and (max-width: 1680px) {
                        max-width: 1100px;
                    }
                    @media screen and (max-width: 1440px) {
                        margin-top: 6.25rem;
                        max-width: 900px;
                        height: 480px;
                    }
                    @media screen and (max-width: 1280px) {
                        max-width: 700px;
                        height: 550px;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 500px;
                        height: 250px;
                    }
                    @media screen and (max-width: 480px) {
                        max-width: 400px;
                        height: 250px;
                        margin-top: 3.25rem;
                    }
                }
            }

            &-home-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 2.688rem;
                margin-bottom: 3.125rem;
                @media screen and (max-width: 1440px) {
                    font-size: 2rem;
                    margin-bottom: 2.5rem;
                    padding-left: 2rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.125rem;
                    margin-bottom: 1.875rem;
                    margin-right: 1rem;
                    margin-left: 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: .5rem;
                    margin-bottom: 1.25rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                    max-width: 200px;
                    margin-left: 0;
                    padding-left: 1.25rem;
                }
            }
            &-publisher-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 40px;
                @media screen and (max-width: 1680px) {
                    max-width: 630px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 10px;
                }
            }
            &-advertiser-title {
                color: #fff;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 3.125rem;
                @media screen and (max-width: 1680px) {
                    max-width: 600px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    margin-bottom: 2.5rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.1rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem;
                    max-width: 420px;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;

                }
                & .orange_title {
                    color: #FCA400;
                }
            }
            &-wir-sind-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                padding-bottom: 1.875rem;
                @media screen and (max-width: 1680px) {
                    max-width: 580px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    padding-bottom: 1.25rem;
                    margin-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem;
                    padding-bottom: 0.625rem;
                    margin-top: 4.25rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                }
            }
            &-wir-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                padding-bottom: 1.875rem;
                @media screen and (max-width: 1680px) {
                    max-width: 580px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    padding-bottom: 1.25rem;
                    margin-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem;
                    padding-bottom: 0.625rem;
                    margin-top: 4.25rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                }
            }
            &-career-title {
                color: #fff;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                margin-bottom: 50px;
                @media screen and (max-width: 1440px) {
                    font-size: 2rem;
                    margin-bottom: 2.5rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                    max-width: 360px;
                    margin-left: 1rem;
                }
            }
            &-contact-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                @media screen and (max-width: 1440px) {
                    font-size: 2rem;
                    line-height: 3.125rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.1rem;
                    padding-top: 3rem;
                    line-height: 2.5rem;
                    margin-bottom: 0;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1.5rem;
                    line-height: 1.875rem;

                }
            }

            &-home-subtitle {
                max-width: 780px;
                width: 100%;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.688rem;
                padding-bottom: 2.5rem;
                @media screen and (max-width: 1680px) {
                    max-width: 500px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 2.188rem;
                    padding-left: 2rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 400px;
                    font-size: 1rem;
                    line-height: 2.5rem;
                    margin-left: 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 200px;
                    font-weight: 400;
                    margin-left: 0;
                    padding-left: 1.25rem;
                }
            }
            &-publisher-subtitle {
                max-width: 780px;
                width: 100%;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 3.125rem;
                @media screen and (max-width: 1680px) {
                    max-width: 630px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 1.875rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 500px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                    /*padding-left: 1rem;*/
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                    padding-left: 0;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 400px;
                    font-weight: 400;
                }

                & span {
                    color: #FCA400;
                }
            }
            &-advertiser-subtitle {
                max-width: 780px;
                width: 100%;
                color: #fff;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 2.5rem;
                @media screen and (max-width: 1680px) {
                    max-width: 680px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 1.25rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 500px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                }
                @media screen and (max-width: 768px) {
                    max-width: 300px;
                    font-size: 1.125rem;
                    line-height: 1.8rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 230px;
                    font-weight: 400;
                }
            }
            &-wir-sind-subtitle {
                max-width: 820px;
                width: 100%;
                height: 460px;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 40px;
                opacity: 1;
                visibility: visible;
                transition: 1s ease-in-out;
                @media screen and (max-width: 1680px) {
                    max-width: 560px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 350px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                    padding-right: 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 0;
                    max-width: 260px;
                    font-weight: 400;
                }

                &.subtitle_ul {
                    @media screen and (max-width: 1680px) {
                        max-width: 500px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 1rem;
                    }
                    @media screen and (max-width: 1280px) {
                        font-size: 1rem;
                        line-height: 2rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                            margin-right: 0.625rem;
                        }
                    }
                }

                & .subtitle_item {
                    padding: 10px 0;
                    list-style-type: none;
                    transition: 1.5s ease-in-out;
                    /*animation-name: animateIn;*/
                    /*animation-duration: 900ms;*/
                    /*animation-delay: calc(var(--animation-order) * 200ms);*/
                    /*animation-fill-mode: forwards;*/
                    /*animation-timing-function: ease-in-out;*/
                    & svg {
                        margin-right: 0.938rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                            width: 30px;
                            height: 20px;
                            margin-right: 0.625rem;
                        }
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                        padding: 5px 0;
                        & svg {
                            width: 24px;
                            height: 20px;
                        }
                    }

                }

            }
            &-wir-subtitle {
                max-width: 820px;
                width: 100%;
                height: 460px;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 40px;
                opacity: 1;
                visibility: visible;
                transition: 1s ease-in-out;
                @media screen and (max-width: 1680px) {
                    max-width: 560px;
                    font-size: 1.3rem;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 350px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                    padding-right: 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 0;
                    max-width: 260px;
                    font-weight: 400;
                }

                &.subtitle_ul {
                    @media screen and (max-width: 1680px) {
                        max-width: 500px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 1rem;
                    }
                    @media screen and (max-width: 1280px) {
                        font-size: 1rem;
                        line-height: 2rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                            margin-right: 0.625rem;
                        }
                    }
                }

                & .subtitle_item {
                    padding: 10px 0;
                    list-style-type: none;
                    transition: 1.5s ease-in-out;
                    /*animation-name: animateIn;*/
                    /*animation-duration: 900ms;*/
                    /*animation-delay: calc(var(--animation-order) * 200ms);*/
                    /*animation-fill-mode: forwards;*/
                    /*animation-timing-function: ease-in-out;*/
                    & svg {
                        margin-right: 0.938rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                            width: 30px;
                            height: 20px;
                            margin-right: 0.625rem;
                        }
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                        padding: 5px 0;
                        & svg {
                            width: 24px;
                            height: 20px;
                        }
                    }

                }

            }
            &-career-subtitle {
                max-width: 870px;
                width: 100%;
                color: #fff;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 2.5rem;
                transition: .5s ease-in-out;
                @media screen and (max-width: 1440px) {
                    font-size: 1rem;
                    padding-bottom: 1.25rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 550px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 1.25rem;
                    max-width: 500px;
                    font-weight: 400;
                    padding-left: 1rem;
                }

                & svg {
                    margin-right: 1.75rem;
                    @media screen and (max-width: 480px) {
                        margin-right: 0.938rem;
                        width: 28px;
                        height: 28px;
                    }

                }

                &.get_right {
                    transition: 2s ease-in-out;
                }
            }

            &-contact-links {
                display: flex;
                flex-direction: column;
                padding: 80px 0;
                @media screen and (max-width: 1440px) {
                    padding: 50px 0;
                }
                @media screen and (max-width: 768px) {
                    padding: 40px 0;
                }
                @media screen and (max-width: 768px) {
                    padding: 30px 0;
                }
                @media screen and (max-width: 480px) {
                    padding: 25px 0 20px;
                }
                & .link_item {
                    padding: 8px 0;
                    color: black;
                    & svg {
                        margin-right: 35px;
                        @media screen and (max-width: 480px) {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }

            &-home-btn {
                padding: 26px 0;
                @media screen and (max-width: 1440px) {
                    padding-left: 2rem;
                }
                @media screen and (max-width: 1280px) {
                    margin-left: 2rem;
                }
                @media screen and (max-width: 480px) {
                    padding: 20px 0;
                    margin-left: 1.25rem;
                }

                & .orange_btn {
                    background: #FCA400;
                    color: white;
                    font-size: 1rem;
                    width: 240px;
                    height: 60px;
                    box-shadow: 0 3px 6px #00000017;
                    border-radius: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    @media screen and (max-width: 1280px) {
                        width: 220px;
                        height: 56px;
                        font-size: 0.938rem;
                    }
                    @media screen and (max-width: 480px) {
                        width: 180px;
                        height: 45px;
                        font-size: 0.813rem;
                    }

                    &:hover {
                        color: white;
                    }
                }
            }

            & .scroll {
                &_content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    top: 90vh;
                    z-index: 2;
                    @media screen and (max-width: 768px) {
                        bottom: 15px;
                    }
                }

                &_text {
                    color: #1B224A;
                    font-size: 0.875rem;
                    margin-bottom: 5px;
                }

                &_img {
                    width: 26px;
                    height: 38px;
                    border-radius: 12px;
                    border: 1px solid #1B224A;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        top: 0;
                        left: 6px;
                        background: url("~@/assets/images/arrow_down_blue.svg") no-repeat center;
                        background-size: contain;
                        animation: mymove 2s infinite;
                        transition: .5s ease-in-out;
                    }
                }
            }

            & .footer_part {
                position: absolute;
                bottom: 1.25rem;
                width: 100%;
                max-width: 1640px;
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
                z-index: 1;
                @media screen and (max-width: 1680px) {
                    max-width: 1460px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                }
                & .links {
                    display: flex;
                    & a {
                        text-decoration: none;
                    }
                    & .link_item {
                        color: #1D53A3;
                        font-weight: 500;
                        margin-right: 3.125rem;
                        cursor: pointer;
                        @media screen and (max-width: 480px) {
                            margin-right: 1.875rem;
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                    }
                }
                & .copyright {
                    color: #606060;
                    font-weight: 500;
                    @media screen and (max-width: 480px) {
                        font-size: 0.75rem;
                        font-weight: 500;
                        max-width: 150px;
                        text-align: center;
                    }
                }
            }
        }
    }

    @keyframes mymove {
        from {
            top: 0;
        }
        to {
            top: 20px;
        }
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes rotating {
        from {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }

    .rotating {
        -webkit-animation: rotating 3s linear infinite;
        -moz-animation: rotating 3s linear infinite;
        -ms-animation: rotating 3s linear infinite;
        -o-animation: rotating 3s linear infinite;
        animation: rotating 3s linear infinite;
    }


</style>