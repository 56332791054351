<template>
    <div class="page_content" v-touch:swipe="swipeHandler">
        <div class="grey_circle" ref="circle_grey"></div>
        <div class="page_content-parts">
            <div class="page_content-data">
                <div class="page_content-title">Kontakt</div>
                <div class="page_content-links">
                    <div class="link_item">
                        <svg id="Group_4023" data-name="Group 4023" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4" data-name="Group 4">
                                <g id="Group_2" data-name="Group 2">
                                    <g id="Ellipse_2" data-name="Ellipse 2" fill="none" stroke="#fca400" stroke-width="1">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                    </g>
                                </g>
                            </g>
                            <g id="map-pin" transform="translate(12 10)">
                                <path id="Path_3" data-name="Path 3" d="M21,10c0,7-9,13-9,13S3,17,3,10a9,9,0,1,1,18,0Z" transform="translate(-3 -1)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="3" cy="3" r="3" transform="translate(6 6)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                        </svg>
                        Uhlandstrasse 28, 10719 Berlin
                    </div>
                    <div class="link_item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4021" data-name="Group 4021" transform="translate(0 0.193)">
                                <path id="phone" d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z" transform="translate(8.388 8.5)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0 -0.193)" fill="none" stroke="#fca400" stroke-width="1">
                                    <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                    <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                </g>
                            </g>
                        </svg>
                        (030) 23 63 13 30
                    </div>
                    <div class="link_item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                            <g id="Group_4022" data-name="Group 4022" transform="translate(0 0.386)">
                                <g id="Group_2" data-name="Group 2">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0 -0.386)" fill="none" stroke="#fca400" stroke-width="1">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                                    </g>
                                </g>
                                <g id="mail" transform="translate(11 13)">
                                    <path id="Path_1" data-name="Path 1" d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z" transform="translate(-2 -4)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_2" data-name="Path 2" d="M22,6,12,13,2,6" transform="translate(-2 -4)" fill="none" stroke="#fca400" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </g>
                        </svg>
                        info@intercontent.de
                    </div>
                </div>
            </div>
            <div class="page_map"></div>
        </div>
        <div class="footer_part">
            <div class="links">
                <router-link to="/impressum">
                    <div class="link_item">Impressum</div>
                </router-link>
                <router-link to="/datenschutz">
                    <div class="link_item">Datenschutz</div>
                </router-link>
            </div>
            <div class="copyright">Copyright 2022 Intercontent GmbH</div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Contact",
        data() {
            return {
                tick: 1,
                halfTick: 0
            }
        },
        methods: {
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        deltaY: 300
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        deltaY: -300
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                let circle = this.$refs['circle_grey']
                this.tick += event.deltaY
                this.halfTick += event.deltaY * 0.01
                circle.style.left = this.tick + 'px';
                circle.style.transform = "scale(" + 1.5 + ")";
                if(this.tick >= 1000) {
                    this.tick = 1000
                    // this.$router.push('/')
                }
                if(this.tick <= 0) {
                    this.$router.push('/karriere')
                }
            }
        },
        mounted() {
            document.addEventListener("wheel", this.handleScroll,);
            this.$store.commit('SHOW_LOADER', false)
        },
        destroyed() {
            document.removeEventListener("wheel", this.handleScroll,);
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_content {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        z-index: 9;
        @media screen and (max-width: 768px) {
            padding: 0 15px;
        }
        & .grey_circle {
            position: absolute;
            bottom: -140px;
            left: 0;
            border-radius: 50%;
            width: 800px;
            height: 800px;
            background: #F3F3F3;
            opacity: 0.79;
            z-index: -1;
            transition: .8s ease-in-out;
        }

        &-parts {
            max-width: 1640px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            @media screen and (max-width: 1680px) {
                max-width: 1460px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 90vw;
            }
            @media screen and (max-width: 1280px) {
                max-width: 1160px;
            }
            @media screen and (max-width: 768px) {
                max-width: 700px;
                flex-direction: column;
                justify-content: unset;
            }
            @media screen and (max-width: 480px) {
                max-width: 450px;
            }
            & .page_map {
                width: 1200px;
                height: 630px;
                background: url("~@/assets/images/map_z.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                @media screen and (max-width: 1680px) {
                    max-width: 1100px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 900px;
                    height: 450px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 700px;
                    height: 550px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 500px;
                    height: 250px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 400px;
                    height: 250px;
                }
            }
        }
        &-title {
            color: #064E82;
            font-size: 3.438rem;
            font-weight: 800;
            line-height: 3.75rem;
            @media screen and (max-width: 1440px) {
                font-size: 2rem;
                line-height: 3.125rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 1.1rem;
                padding-top: 3rem;
                line-height: 2.5rem;
                margin-bottom: 0;
            }
            @media screen and (max-width: 480px) {
                font-size: 1.5rem;
                line-height: 1.875rem;

            }
        }
        &-links {
            display: flex;
            flex-direction: column;
            padding: 80px 0;
            @media screen and (max-width: 1440px) {
                padding: 50px 0;
            }
            @media screen and (max-width: 768px) {
                padding: 40px 0;
            }
            @media screen and (max-width: 768px) {
                padding: 30px 0;
            }
            @media screen and (max-width: 480px) {
                padding: 25px 0 20px;
            }
            & .link_item {
                padding: 8px 0;
                color: black;
                & svg {
                    margin-right: 35px;
                    @media screen and (max-width: 480px) {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }
        &-data {
            @media screen and (max-width: 1440px) {
                margin-top: 1.25rem;
            }
            @media screen and (max-width: 768px) {
                margin-top: 0;
            }
            @media screen and (max-width: 480px) {
                margin-bottom: 0;
            }
        }
        & .footer_part {
            position: absolute;
            bottom: 1.25rem;
            width: 100%;
            max-width: 1640px;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            @media screen and (max-width: 1680px) {
                max-width: 1460px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 90vw;
            }
            @media screen and (max-width: 1280px) {
                max-width: 1160px;
            }
            @media screen and (max-width: 768px) {
                max-width: 700px;
            }
            @media screen and (max-width: 480px) {
                max-width: 450px;
            }
            & .links {
                display: flex;
                & a {
                    text-decoration: none;
                }
                & .link_item {
                    color: #1D53A3;
                    font-weight: 500;
                    margin-right: 3.125rem;
                    cursor: pointer;
                    @media screen and (max-width: 480px) {
                        margin-right: 1.875rem;
                        font-size: 0.75rem;
                        font-weight: 600;
                    }
                }
            }
            & .copyright {
                color: #606060;
                font-weight: 500;
                @media screen and (max-width: 480px) {
                    font-size: 0.75rem;
                    font-weight: 500;
                    max-width: 150px;
                    text-align: center;
                }
            }
        }
    }
}

</style>