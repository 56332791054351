<template>
    <div class="page_content"  v-touch:swipe="swipeHandler">
        <div class="grey_circle" ref="circle_grey"></div>
        <div class="page_content-parts content_break" ref="page_content_parts">
            <div class="page_content-man" ref="black_man"></div>
            <div class="page_content-data-man" ref="page_data_man">
                <div class="page_content-title">Signifikanter Unterschied</div>
                <div class="page_content-subtitle">Wir nutzen uns zur Verfügung stehenden qualitativ hochwertigen
                    Traffic zur Monetarisierung und steigern nicht nur die KPI unserer Partner, sondern erzielen sofort
                    Umsatzsteigerungen.
                </div>
            </div>
            <div class="page_content-data" ref="wir_sind_data">
                <div class="page_content-title">Wir</div>
                <ul class="page_content-subtitle subtitle_ul" ref="sub_ul">
                    <li class="subtitle_item" style="--animation-order: 1;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        VERARBEITEN >3.5 MILLION KLICKS / MONAT
                    </li>
                    <li class="subtitle_item" style="--animation-order: 2;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        SIND PARTNER VON 20+ NETZWERKE
                    </li>
                    <li class="subtitle_item" style="--animation-order: 3;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        PRÄSENTIEREN PRODUKTE VON >1,000 MERCHANTS
                    </li>
                    <li class="subtitle_item" style="--animation-order: 4;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        UNSERE SERVICES SIND WELTWEIT AUSGEROLLT
                    </li>
                    <li class="subtitle_item" style="--animation-order: 5;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="26.846" viewBox="0 0 36 26.846">
                            <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                  d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                                  transform="translate(0 -4.577)" fill="#fca400"/>
                        </svg>
                        HABEN EINE SICHTBARKEIT VON 90 % DER ANDROID-NUTZER
                    </li>
                </ul>
            </div>
            <div class="page_content-girl" ref="blue_girl"></div>
        </div>
        <div class="scroll_content">
            <div class="scroll_text">Scroll Down</div>
            <div class="scroll_img"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WirSind",
        data() {
            return {
                tick: 40,
                halfTick: 1,
            }
        },
        methods: {
            swipeHandler(direction) {
                if(direction === 'bottom') {
                    let event= {
                        deltaY: 300
                    }
                    this.handleScroll(event)
                } else if(direction === 'top') {
                    let event= {
                        deltaY: -300
                    }
                    this.handleScroll(event)
                }
            },
            handleScroll(event) {
                let girl = this.$refs['blue_girl']
                let circle = this.$refs['circle_grey']
                let get_right = document.getElementsByClassName('subtitle_item')
                let black_man = this.$refs['black_man']
                let page_data_man = this.$refs['page_data_man']
                let page_content_parts = this.$refs['page_content_parts']
                let wir_sind_data = this.$refs['wir_sind_data']
                let sub_ul = this.$refs['sub_ul']
                this.tick += -event.deltaY * 0.4
                this.halfTick += event.deltaY * 0.2
                girl.style.right = this.tick + 'px';
                circle.style.transform = "scale(" + 2 + ")";
                black_man.style.transform = "scale(" + 1.2 + ")";
                page_content_parts.style.transform = `translateX(40px)`;

                if (this.tick <= -300) {
                    this.tick = -300

                    black_man.style.opacity = 0
                    black_man.style.width = 0
                    black_man.style.visibility = 'hidden'
                    page_data_man.style.opacity = 0
                    page_data_man.style.width = 0
                    page_data_man.style.visibility = 'hidden'

                    wir_sind_data.style.opacity = 1
                    wir_sind_data.style.visibility = 'visible'
                    wir_sind_data.style.width = '60%'

                    girl.style.opacity = 1
                    girl.style.visibility = 'visible'
                    girl.style.width = '650px'
                    page_content_parts.style.transform = `translateX(0px)`;
                    page_content_parts.style.justifyContent = 'space-between'
                    // page_content_parts.style.flexDirection = 'column'
                    // page_content_parts.style.alignItems = 'flex-start'


                    // sub_ul.style.opacity = 1
                    // sub_ul.style.visibility = 'visible'
                }
                if (this.halfTick >= 250) {
                    let move = 0
                    for (let i = 0; i < get_right.length; i++) {
                        get_right[i].style.transform = `translateX(${move}px)`;
                        move += 50
                    }
                }
                if (this.tick >= 80) {
                    this.$router.push('/advertiser')
                }
                if (this.halfTick >= 400) {
                    this.$router.push('/karriere')
                }
            },

        },

        mounted() {
            document.addEventListener("wheel", this.handleScroll,);
            this.$store.commit('SHOW_LOADER', false)
        },
        destroyed() {
            document.removeEventListener("wheel", this.handleScroll,);
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }

            &-girl {
                width: 0;
                height: 100vh;
                background: url("~@/assets/images/smiling_girl.png");
                background-repeat: no-repeat;
                right: 40px;
                position: relative;
                background-position: center;
                background-size: cover;
                transition: 1.8s ease-in-out;
                transform: scale(1.8);
                z-index: 2;
                @media screen and (max-width: 768px) {
                    height: 60vh;
                }
                @media screen and (max-width: 480px) {
                    position: absolute;
                    width: 470px;
                    height: 75vh;
                    background-position: bottom;
                    background-size: contain;
                    opacity: 0;
                    visibility: hidden;
                    right: -200px;
                }
            }

            &-man {
                /*display: none;*/
                width: 720px;
                height: 100vh;
                background: url("~@/assets/images/black_male.png");
                background-repeat: no-repeat;
                background-position: top;
                background-size: cover;
                transition: 1.8s ease-in-out;
                @media screen and (max-width: 1680px) {
                    width: 720px;
                }
                @media screen and (max-width: 1680px) {
                    width: 700px;
                }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    height: 100vh;
                    background-position: bottom;
                    background-size: contain;
                    position: absolute;
                }
                @media screen and (max-width: 480px) {
                    width: 520px;
                    height: 100vh;
                    left: 55px;
                }
            }

            & .grey_circle {
                position: absolute;
                bottom: -250px;
                right: -40px;
                border-radius: 50%;
                width: 750px;
                height: 750px;
                background: #FCA400;
                z-index: 1;
                opacity: 0.05;
                transition: .8s ease-in-out;
            }

            &-data {
                transition: .9s ease-in-out;
                opacity: 0;
                visibility: hidden;
                width: 0;
                @media screen and (max-width: 1440px) {
                    margin-top: 1.875rem;
                    display: flex;
                    flex-direction: column;
                    height: 80vh;
                    justify-content: center;
                }
                @media screen and (max-width: 480px) {
                    transform: translateY(-55%);
                }

                &-man {
                    opacity: 1;
                    visibility: visible;
                    width: 580px;
                    z-index: 10;
                    @media screen and (max-width: 1440px) {
                        margin-top: 2.5rem;
                        display: flex;
                        flex-direction: column;
                        height: 80vh;
                        justify-content: center;
                    }
                    @media screen and (max-width: 768px) {
                        width: 500px;
                        height: 50vh;
                    }
                    @media screen and (max-width: 480px) {
                        margin-top: 3.125rem;
                    }
                }
            }

            &-parts {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                transition: 1.8s ease-in-out;
                @media screen and (max-width: 1680px) {
                    max-width: 1500px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 90vw;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1160px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                    flex-direction: initial;
                    align-items: flex-start;
                }
                @media screen and (max-width: 480px) {
                    max-width: 450px;
                    align-items: center;
                }
                &.content_break {
                    @media screen and (max-width: 480px) {
                        flex-direction: column;
                        align-items: flex-start;
                        max-height: 100vh;
                        padding: 75px 0;
                    }

                }
            }

            &-title {
                color: #064E82;
                font-size: 3.438rem;
                font-weight: 800;
                line-height: 3.75rem;
                max-width: 820px;
                width: 100%;
                padding-bottom: 1.875rem;
                @media screen and (max-width: 1680px) {
                    max-width: 580px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 2.5rem;
                    padding-bottom: 1.25rem;
                    margin-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 2rem;
                    line-height: 3.438rem;
                    margin-bottom: 1.875rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                    line-height: 2.5rem;
                    margin-bottom: 1.25rem;
                    padding-bottom: 0.625rem;
                    margin-top: 4.25rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.625rem;
                }
            }

            &-subtitle {
                max-width: 820px;
                width: 100%;
                height: 460px;
                color: #1B224A;
                font-weight: 600;
                font-size: 1.563rem;
                line-height: 2.5rem;
                padding-bottom: 40px;
                opacity: 1;
                visibility: visible;
                transition: 1s ease-in-out;
                @media screen and (max-width: 1680px) {
                    max-width: 560px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 1.2rem;
                    padding-bottom: 0.313rem;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 350px;
                    font-size: 1rem;
                    line-height: 2.125rem;
                    padding-bottom: 0.938rem;
                    padding-right: 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 0.8rem;
                    line-height: 1.75rem;
                }
                @media screen and (max-width: 480px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-bottom: 0;
                    max-width: 260px;
                    font-weight: 400;
                }

                &.subtitle_ul {
                    @media screen and (max-width: 1680px) {
                        max-width: 500px;
                    }
                    @media screen and (max-width: 1440px) {
                        font-size: 1rem;
                    }
                    @media screen and (max-width: 1280px) {
                        font-size: 1rem;
                        line-height: 2rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                          margin-right: 0.625rem;
                        }
                    }
                }

                & .subtitle_item {
                    padding: 10px 0;
                    list-style-type: none;
                    transition: 1.5s ease-in-out;
                    /*animation-name: animateIn;*/
                    /*animation-duration: 900ms;*/
                    /*animation-delay: calc(var(--animation-order) * 200ms);*/
                    /*animation-fill-mode: forwards;*/
                    /*animation-timing-function: ease-in-out;*/
                    & svg {
                        margin-right: 0.938rem;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 0.8rem;
                        line-height: 1.375rem;
                        & svg {
                            width: 30px;
                            height: 20px;
                            margin-right: 0.625rem;
                        }
                    }
                    @media screen and (max-width: 480px) {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                        padding: 5px 0;
                        & svg {
                            width: 24px;
                            height: 20px;
                        }
                    }

                }

            }

            & .scroll {
                &_content {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    bottom: 40px;
                    @media screen and (max-width: 768px) {
                        bottom: 15px;
                    }
                }

                &_text {
                    color: #1B224A;
                    font-size: 0.875rem;
                    margin-bottom: 5px;
                }

                &_img {
                    width: 26px;
                    height: 38px;
                    border-radius: 12px;
                    border: 1px solid #1B224A;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        top: 0;
                        left: 6px;
                        background: url("~@/assets/images/arrow_down_blue.svg") no-repeat center;
                        background-size: contain;
                        animation: mymove 2s infinite;
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
    }

    @keyframes mymove {
        from {
            top: 0;
        }
        to {
            top: 20px;
        }
    }

    @keyframes animateIn {
        0% {
            opacity: 0;
            transform: scale(0.6) translateY(-8px);
        }

        100% {
            opacity: 1;
        }
    }
</style>